/* CSS Document */

/* 
*
*
[Table of contents]
*
*
*

  color / $white
  font / @import 
  general / html
  dropdown / .dropdown-custom.btn
  navbar / .navbar
  breadcumb / .breadcumb
  home / .subheading
  icon scroll / .icon-scroll-wraper
  footer / footer
  portofolio / AppContainer
  contact / .form-side
  news / .post-content
  create file / .d-create-file
  activity / .filter__l
  feature box / .feature-box .inner
  column nft / .nft_coll
  countdown / .de_countdown
  author list / .author_list
  icon box / .icon-box 
  carousel / .d-carousel
  filter / .items_filter
  colection / .profile_banner 
  item details / .item_info
  wallet / .box-url 
  login / .field-set label
  all icon / .demo-icon-wrap-s2 
  element / .card-header
  media all / @media only screen and (max-width: 1199px)

*/

/*color*/
$white: #fff;
$gray: #bbb;
$black: #111;
$general: #727272;
$black_soft: #333;
$black_col: #2e333c;
$black_more: #0f0f0f;
$color: #8364e2;
$color_more: #8364e2;

.col-white {
  color: $white;
}

/*font*/
@import url("https://fonts.googleapis.com/css2?family=Bungee&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;200;300;400&display=swap");

@font-face {
  font-family: teko;
  src: url("./teko/Teko-Regular.ttf");
}

@font-face {
  font-family: familjenGrotesk;
  src: url("./familjenGrotesk/FamiljenGrotesk-Medium.ttf");
}


/*general*/
html {
  scroll-behavior: auto;
}
body > #webpack-dev-server-client-overlay {
  display: none;
}
body {
  font-family: familjenGrotesk;
  font-weight: 300;
  font-size: 14px;
  color: $black;
  background: $white;
  word-spacing: 0px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: $color;
}
.uppercase-first-letter::first-letter {
  text-transform: uppercase;
}
#routerhang {
  transform: unset !important;
  transform-origin: unset !important;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.header {
  font-family: "familjenGrotesk";
  font-weight: bold;
}

.br {
  display: block;
  white-space: pre-wrap;
}

strong {
  font-weight: bold;
}

.jumbotron {
  background-color: #f0f0f0;
  margin-bottom: 30px;
  padding-top: 40px;
  padding-bottom: 16px;
}

section.no-top,
section.container {
  padding-bottom: 10px;
}

section {
  &.no-top {
    padding-top: 0 !important;
  }

  &.no-bottom {
    padding-bottom: 0 !important;
    padding-top: 0px !important;
    margin-top: -5% !important;
    z-index: 1000 !important;
  }

  .small-border {
    display: block;
    width: 50px;
    height: 2px;
    background: $color;
    border-left: none;
    border-left-color: currentcolor;
    border-right: none;
    border-right-color: currentcolor;
    margin: 18px auto 30px;
  }

  &.bg-gray {
    background: rgb(247, 244, 253);
  }
}

.wraper {
  padding: 0;
  display: block;
  overflow: hidden;
  width: 100%;

  .wraperitem {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}

.m-2-hor {
  padding: 0 7%;
  max-width: 1500px;
  margin: 0 auto !important;
}

.m-10-hor {
  display: flex;
  justify-content: space-between;

  padding-right: 10%;
  width: 100%;
  margin: 0 auto;
}

.align-items-center {
  align-items: center !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.white {
  color: $black;
  background: $white;
}

.black_more {
  background: $black_more;
}

.color {
  color: black;
}

.onStep h1 {
  font-weight: 300;
}

.btn {
  position: relative;
  overflow: hidden;
  font-family: familjenGrotesk;
  font-size: 11pt;
  // color: $white;
  // background: none;
  // border: 2px solid $color;
  border: 2px solid;
  border-radius: 0;
  padding: 10px 44px;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  z-index: 0;

  span {
    color: $white;
    position: relative;
    z-index: 1;
  }

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    background-image: linear-gradient(to left, $color, $color_more);
    opacity: 0;
    z-index: 1;
    transition: all 0.3s ease;
  }

  &:hover::before {
    opacity: 1;
    transition: all 0.3s ease;
  }

  &:hover {
    .shine {
      -webkit-animation: sheen 0.6s alternate;
      animation: sheen 0.6s alternate;
    }
  }
}

.shine {
  content: "";
  position: absolute !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: -50px;
  background: -webkit-linear-gradient(
    top,
    transparent,
    rgba(255, 255, 255, 0.2) 5%,
    transparent
  );
  background: linear-gradient(
    to bottom,
    transparent,
    rgba(255, 255, 255, 0.2) 5%,
    transparent
  );
  -webkit-transform: rotateZ(60deg) translate(-1em, 5.5em);
  transform: rotateZ(60deg) translate(-1em, 5.5em);
}


@-webkit-keyframes sheen {
  0% {
    opacity: 1;
    -webkit-transform: rotateZ(60deg) translate(0, 6em);
    transform: rotateZ(60deg) translate(0, 6em);
  }

  90% {
    opacity: 1;
    -webkit-transform: rotateZ(60deg) translate(0, -12em);
    transform: rotateZ(60deg) translate(0, -12em);
  }

  100% {
    opacity: 0;
    -webkit-transform: rotateZ(60deg) translate(0, -12em);
    transform: rotateZ(60deg) translate(0, -12em);
  }
}

@keyframes sheen {
  0% {
    opacity: 1;
    -webkit-transform: rotateZ(60deg) translate(0, 6em);
    transform: rotateZ(60deg) translate(0, 6em);
  }

  90% {
    opacity: 1;
    -webkit-transform: rotateZ(60deg) translate(0, -12em);
    transform: rotateZ(60deg) translate(0, -12em);
  }

  100% {
    opacity: 0;
    -webkit-transform: rotateZ(60deg) translate(0, -12em);
    transform: rotateZ(60deg) translate(0, -12em);
  }
}

.skeleton {
  background-color: #e0e0e0;  /* A light grey color */
  border-radius: 4px;
  overflow: hidden;
  position: relative;
}

.skeleton::before {
  content: "";
  display: block;
  padding-top: 100%;  /* This gives a 1:1 aspect ratio */
}

@keyframes shimmer {
  0% { background-position: -200% 0; }
  100% { background-position: 200% 0; }
}

.skeleton.shimmer {
  background: linear-gradient(90deg, #e0e0e0 25%, #f3f3f3 50%, #e0e0e0 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
}
.off-screen {
  position: absolute;
  left: -9999px;
  visibility: hidden; // this ensures it's not visible but can still load
}

.text-gradient {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  display: inline-block;
  background: linear-gradient(to bottom, $color, $color_more);
}

.spacer-10 {
  width: 100%;
  height: 10px;
  display: block;
  clear: both;
}

.rotate-90 {
  transform: rotate(90deg);
}

.de-flex {
  display: flex;
  justify-content: space-between;
}

.de-flex > .de-flex-col {
  display: flex;
  justify-content: center;
  align-items: center;
}

.social_icon {
  margin: 0 10px;
  cursor: pointer;

  &:hover {
    color: $color;
  }
}

/**** divider ***/
.spacer-single {
  width: 100%;
  height: 30px;
  display: block;
  clear: both;
}

.spacer-double {
  width: 100%;
  height: 60px;
  display: block;
  clear: both;
}

.spacer-half {
  width: 100%;
  height: 15px;
  display: block;
  clear: both;
}

.spacer-5 {
  width: 100%;
  height: 5px;
  display: block;
  clear: both;
}

.spacer-10 {
  width: 100%;
  height: 10px;
  display: block;
  clear: both;
}

.spacer-20 {
  width: 100%;
  height: 20px;
  display: block;
  clear: both;
}

.spacer-30 {
  width: 100%;
  height: 30px;
  display: block;
  clear: both;
}

.spacer-40 {
  width: 100%;
  height: 40px;
  display: block;
  clear: both;
}

.spacer-50 {
  width: 100%;
  height: 50px;
  display: block;
  clear: both;
}

.spacer-60 {
  width: 100%;
  height: 60px;
  display: block;
  clear: both;
}

hr {
  display: block;
  clear: both;
  border-top: solid 1px #ddd;
  margin: 40px 0 40px 0;
}

/**** heading ***/
h1,
h2,
h4,
h3,
h5,
h6,
.h1_big,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-top: 0;
  font-family: teko;
  color: #0d0c22;
}

h1,
.h1 {
  font-size: 50px;
  line-height: 1.2em;
  letter-spacing: -1px;
}

h1 .label {
  display: inline-block;
  font-size: 36px;
  padding: 0 6px;
  margin-left: 10px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

h1.s1 {
  letter-spacing: 30px;
  font-size: 26px;
}

h1 .small-border {
  margin-top: 30px;
}

h1.big,
.h1_big {
  font-size: 64px;
  margin: 0;
  line-height: 70px;
}

h1.very-big {
  font-size: 120px;
  letter-spacing: -5px;
}

h1.ultra-big {
  font-size: 140px;
  line-height: 120px;
  letter-spacing: -6px;
  font-weight: 700;
  margin-bottom: 0;
}

h1.ultra-big span {
  display: inline-block;
}

h1.ultra-big span.underline span {
  display: block;
  border-bottom: solid 12px #fff;
  position: relative;
  margin-top: -5px;
}

h1.very-big-2 {
  font-size: 90px;
  letter-spacing: 25px;
  text-transform: uppercase;
  font-weight: bold;
}

.h2_title {
  font-size: 28px;
  display: block;
  margin-top: 0;
  line-height: 1.2em;
}

h2 .small-border {
  margin-left: 0;
  margin-bottom: 15px;
  width: 40px;
}

h2 {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 36px;
  line-height: 46px;
}

h2.style-2 {
  font-size: 50px;
  margin-bottom: 20px;
  padding: 0 !important;
}

h2.big {
  font-size: 48px;
  line-height: 1.3em;
  margin-bottom: 0;
}

h2 .uptitle {
  display: block;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0;
  font-weight: 500;
}

h2.s1,
.h2_s1 {
  font-size: 24px;
}

h2.deco-text span {
  font-family: "Parisienne";
  display: block;
  line-height: 0.85em;
  font-weight: lighter;
  -webkit-transform: rotate(-5deg);
  -moz-transform: rotate(-5deg);
  -o-transform: rotate(-5deg);
  transform: rotate(-5deg);
}

h2.deco-text .md {
  font-size: 80px;
}

h2.deco-text .lg {
  font-size: 120px;
}

h2.deco-text .xl {
  font-size: 150px;
}

h3 {
  font-size: 22px;
  margin-bottom: 25px;
}

h4 {
  font-size: 20px;
  margin-top: 0;
  margin-bottom: 10px;
}

h4.teaser {
  font-weight: 300;
  font-size: 22px;
}

.subtitle.s2 {
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
}

.subtitle.s2 span {
  margin-right: 20px;
  padding-left: 20px;
}

.subtitle.s2 i {
  margin-right: 10px;
}

.subtitle.s2 span:first-child {
  padding-left: 0;
}

h4.s1 {
  letter-spacing: 10px;
  font-weight: 400;
  font-size: 16px;
}

h4.s2 {
  font-family: "Inter", Arial, Helvetica, sans-serif;
  font-weight: 200;
  line-height: 1.8em;
}

h4.s3 {
  font-family: "Parisienne";
  font-size: 60px;
  font-weight: lighter;
}

h4.s3 {
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 15px;
}

.call-to-action h4 {
  text-transform: none;
  font-size: 20px;
}

h1.slogan_big {
  font-weight: 400;
  font-size: 64px;
  line-height: 64px;
  letter-spacing: -2px;
  padding: 0;
  margin: 0px 0 30px 0;
}

h1.title {
  font-size: 64px;
  letter-spacing: 10px;
}

h1.title strong {
  text-transform: none;
  letter-spacing: 0;
  font-weight: normal;
}

h1.hs1 {
  font-family: "Parisienne";
  font-size: 96px;
  display: inline-block;
  -webkit-transform: rotate(-5deg);
  -moz-transform: rotate(-5deg);
  -o-transform: rotate(-5deg);
  transform: rotate(-5deg);
}

h1.hs2 {
  font-family: "Parisienne";
  font-size: 72px;
  display: inline-block;
  font-weight: lighter;
}

h2.subtitle {
  margin-top: 0;
}

h2.name {
  color: #fff;
  font-size: 84px;
  line-height: 50px;
}

h2.name span {
  display: block;
  font-size: 32px;
}

h2.name-s1 {
  color: #fff;
  font-size: 84px;
  font-weight: 700;
  line-height: 50px;
}

h2.name-s1 span {
  display: block;
  font-size: 32px;
}

h2.hw {
  display: block;
  font-family: "Parisienne";
  font-size: 48px;
  text-transform: none;
  font-weight: lighter;
}

h2.deco {
  text-align: center;
  font-weight: 600;
  font-size: 20px;
}

h2.deco span {
  display: inline-block;
  position: relative;
}

h2.deco span:before,
h2.deco span:after {
  content: "";
  position: absolute;
  border-top: 1px solid #bbb;
  top: 10px;
  width: 100px;
}

h2.deco span:before {
  right: 100%;
  margin-right: 15px;
}

h2.deco span:after {
  left: 100%;
  margin-left: 15px;
}

h2.hs1 {
  font-size: 40px;
}

h2.hs1 i {
  font-size: 48px;
  position: relative;
  top: 10px;
  color: #ff0042;
  margin: 0 10px 0 10px;
}

h2.hs1 span {
  font-size: 48px;
  position: relative;
  top: 10px;
  font-family: "Miama";
  margin: 0 15px 0 10px;
  font-weight: normal;
}

h2 .de_light .text-light h2 {
  color: #fff;
}

.text-light h2.deco span:before,
.text-light h2.deco span:after {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
}

h2.s2 {
  font-weight: 400;
}

h2.s3 {
  font-size: 36px;
  margin-bottom: 20px;
}

h4.title {
  border-bottom: solid 1px #ddd;
  margin-bottom: 20px;
}

h4.style-2 {
  font-size: 18px;
}

h4.title {
  border-bottom: solid 1px #ddd;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

h5.s2 {
  letter-spacing: 1px;
  font-size: 14px;
}

h5 {
  font-size: 24px;
}

h4.s1 {
  font-size: 14px;
  letter-spacing: 20px;
  text-transform: uppercase;
  font-weight: 500;
}

.col-xs-12 h5 {
  margin-bottom: 10px;
}

p.lead {
  font-size: 14px;
  line-height: 18px;
  margin-top: 0;
  font-weight: 500;
}

.field-set .create-btn {
  display: flex;
  justify-content: flex-start;
}

.field-set .btn-main {
  background-color: $color;
  color: white;
}

.profile-title {
  margin: 20px 0;
  font-size: 26px;
}

.full-name {
  display: flex;
}

.first-name {
  margin-right: 20px;
}

.update-btn {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;

  @media screen and (max-width: 991px) {
    &.desktop {
      display: none;
    }
  }

  &.mobile {
    display: none;
  }

  @media screen and (max-width: 991px) {
    &.mobile {
      display: flex;
    }
  }

  button {
    color: white;
  }
}

input#upload_profile_img,
input#upload_banner_img {
  color: $color !important;
  background-color: white !important;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  text-transform: uppercase;

  .btn-main:first-child {
    margin-right: 10px;
    color: white;
  }

  .btn-main:last-child {
    margin-right: 0;
    color: #8364e2;
    outline: 1px solid #8364e2;
  }
}

.explore {
  background-color: $color !important;
}

.coin {
  padding-top: 10px;
}

.coin img {
  width: 18% !important;
}

.coin h6,
.coin p {
  font-size: 8px;
  color: $color;
}

.jumbo-imgs {
  display: flex;
  align-items: flex-end;

  &.home_custom {
    .featured_btn {
      margin-right: 10px;

      img {
        margin: 0;
      }
    }

    p {
      line-height: 1.1;
    }

    .profile-img {
      width: 50px;
      height: 50px;
    }
  }

  img {
    width: 80px;
    height: 80px;
    margin-left: 10px;
    object-fit: cover;
    object-position: center;
  }

  .profile-img {
    width: 60px;
    height: 60px;
    overflow: hidden;
    border-radius: 50%;
    object-fit: cover;

    img {
      width: 100%;
      height: 100%;
      margin-left: 0;
      margin-bottom: 6px;
    }
  }

  .de_count {
    margin-left: 12px;

    p {
      font-size: 14px;
      color: #8364e2;
      line-height: 15px;
      font-weight: 500;
      margin-bottom: 0;
    }
  }
}

.side-feature {
  display: flex !important;
  align-items: flex-end;
  padding-left: 3rem;

  img {
    width: 65%;
    @media only screen and (min-width: 768px) {
      width: 400px;
      height: 350px;
    }
    @media only screen and (min-width: 576px) {
      width: 250px;
      height: 350px;
    }
  }
}

.side-feature div {
  margin-left: 10px;

  .side-name {
    color: #8364e2;
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
  }
}

.btn-secondary {
  display: block;
  // width: max-content;
  // min-width: 140px;
  text-transform: uppercase;
  text-align: center;
  background: white;
  border-radius: 5px;
  letter-spacing: normal;
  outline: 0;
  font-family: familjenGrotesk;
  font-weight: 500;
  text-decoration: none;
  min-height: 30px;
  min-width: 120px;
  padding: 2px;
  font-size: 12px;
  color: $color;
  border: 1px solid $color;
  cursor: pointer;
  transition: all 0.3s ease;
  word-wrap: normal;
  &.tiny {
    font-size: 10px;
    background-color: white;
    color: $color;
    padding: 2px 8px;
    margin: 0 5px;
    height: fit-content;
    min-width: fit-content;
  }
  &.no-transform {
    text-transform: none;
  }
  &:hover {
    background: white;
    color: $color;
    font-weight: 700;
    box-shadow: 6px 6px 0px 0px rgba(0, 0, 0, 0.09);
    transition: all 0.3s ease;
  }
  &:disabled,
  &[disabled] {
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
    cursor: not-allowed;
  }
}

.btn-disabled {
  opacity: 0.7;
}

.btn-main {
  display: block;
  text-transform: uppercase;
  // min-width: 140px;
  // width: max-content;
  text-align: center;
  background: $color;
  border-radius: 5px;
  letter-spacing: normal;
  outline: 0;
  font-family: familjenGrotesk;
  font-weight: 500;
  text-decoration: none;
  min-width: 120px;
  padding: 2px;
  min-height: 30px;
  font-size: 12px;
  color: white;
  word-wrap: normal;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;

  &.tiny {
    font-size: 10px;
    background-color: #efefef;
    color: #000000;
    padding: 2px 8px;
    margin: 0 5px;
    height: fit-content;
    min-width: fit-content;
  }
  &:hover {
    font-weight: 700;
    box-shadow: 6px 6px 0px 0px rgba(0, 0, 0, 0.09);
    transition: all 0.3s ease;
  }

  &.inline {
    display: inline-block;
    margin-right: 15px;

    &.white {
      color: $color_more !important;
      background: $white;
    }
  }

  &:disabled,
  &[disabled] {
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
    cursor: not-allowed;
  }

  &.green {
    border: 1px solid #549205;
    background-color: #91f411;
  }
}

/* dropdown */
.dropdown-custom.btn {
  font-size: 14px;
  border: 0px;
  position: relative;
  top: -1px;
  overflow: unset !important;
  letter-spacing: normal;
  font-weight: 800;
  padding: 0 20px 0 0;
  background: none !important;

  &::before {
    content: "";
    position: absolute;
    top: -10px;
    left: 19px;
    width: max-content;
    height: auto;
    padding: 30px 40px;
    background: 0;
  }

  &::after {
    margin-left: 5px !important;
  }

  &:focus {
    box-shadow: none !important;
  }

  &:hover {
    color: $color;
  }
}

.dropdown-toggle::after {
  font-family: FontAwesome;
  content: "\f078";
  color: $color;
  padding-left: 2px;
  font-size: 8px;
  top: -1px;
  position: absolute;
  top: 6px;
  right: 5px;
  border: none;
}

.item-dropdown {
  width: max-content;
  position: absolute;
  background: #faf6f1;
  border-radius: 0px;
  border: solid 1px #888;
  inset: 50px auto auto 0;
  padding: 0 0;
  animation: smoothDrop 0.2s ease;
  -webkit-animation: smoothDrop 0.2s ease;
  z-index: 1;

  .dropdown {
    position: relative;
    text-align: center;

    a {
      color: $color !important;
      text-transform: none;
      font-weight: normal;
      text-decoration: none;
      letter-spacing: normal;
      display: block;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      padding: 8px 20px 8px 20px;
      min-width: 210px;
      width: 100%;
      text-align: left;

      &:hover {
        color: $white !important;
        background: $color;
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }
}

.imgslickz {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

/*navbar*/
.mainside a i {
  display: none;
}

.navbar {
  padding: 0;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  background: rgba(0, 0, 0, 0);
  background-color: white;
  border-bottom: solid 1px rgba(255, 255, 255, 0);
  z-index: 999;
  transition: all 0.6s ease;

  &.white {
    a {
      color: #a3a2a2;
    }

    .btn {
      color: $color;

      .lines {
        top: 24px;
      }
    }
  }

  .w-100-nav {
    margin: 0;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
  }

  a {
    position: relative;
    font-size: 14px;
    font-weight: 800;
    padding: 0 5px 0 0;
    text-decoration: none !important;
    // color: lightgrey;
    outline: none;
    transition: all 0.3s ease;

    // &:hover {
    //   color: black;
    //   opacity: 0.6;
    // }

    &.active {
      transition: all 0.3s ease;

      &:before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 0;
        left: 0;
        top: 0;
        background: 0;
        cursor: default;
      }
    }
  }

  .navbar-title {
    margin-right: auto;
    font-size: 150%;
    padding: 12px 16px;
  }

  .navbar-item {
    position: relative;
    display: inline-block;
    padding: 30px 10px;
    height: max-content;
    color: lightgrey;
    cursor: default;
    margin-left: 10px;

    // &.active a {
    //   color: black;
    //   opacity: 0.6;
    // }

    // .lines {
    //   position: absolute;
    //   top: 22px;
    //   display: block;
    //   width: 0;
    //   border-bottom: 2px solid $color;
    //   transition: 0.7s;
    // }
    // &:hover {
    //   .lines {
    //     width: 90%;
    //     transition: 0.7s;
    //   }
    // }
  }

  .breakpoint__xl-only {
    width: max-content;
    margin-left: auto;
  }

  .logo {
    width: max-content;
    padding-right: 30px;

    .navbar-item {
      position: relative;
      top: 2px;
      left: 0px;
      padding: 0;
      width: max-content;
      border-bottom: none;

      a {
        padding: 0;

        .d-3 {
          display: none;
        }
      }
    }
  }

  .menu {
    display: flex;
    justify-content: flex-end;
    width: max-content;
    color: lightgray;
  }

  .nav-icon {
    display: none;
  }

  @media only screen and (max-width: 1199px) {
    .m-2-hor {
      padding: 0px 2%;
    }

    .menu {
      display: block;
      padding: 15px 0 10px;
    }

    &.navbar {
      position: relative;

      .logo {
        display: inline-block;
      }

      .search {
        display: inline-block;
        position: relative;
        top: -5px;

        #quick_search {
          width: 100%;
        }
      }

      .mainside {
        position: absolute;
        right: 60px;
        top: 42px;
      }

      .dropdown-custom.btn {
        color: $color;
        width: 100%;
        text-align: left;

        &::after {
          float: right;
          font-size: 0.9rem;
        }
      }
    }

    .w-100-nav {
      display: block;
      padding: 20px 2%;
    }

    .navbar-item {
      display: block;
      padding: 15px 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }

    .nav-icon {
      position: absolute;
      top: 55px;
      right: 25px;
      display: block;
      width: 20px;
      height: 10px;
      padding-top: 13px;
      line-height: 0;
      cursor: pointer;
      background: none;
      border: none;
      padding: 0;
      z-index: 9999;
    }

    .bg-nav-icon {
      width: 32px;
      height: 24px;
      background: rgba(255, 255, 255, 0);
      border-radius: 2px;
      -moz-border-radius: 2px;
      -webkit-border-radius: 2px;
    }

    #nav-icon span,
    .nav-icon span {
      display: none;
      position: absolute;
      top: 5px;
      left: -40px;
      font-size: 0.62rem;
      font-weight: 600;
      text-transform: uppercase;
    }

    .menu-line {
      position: absolute;
      width: 100%;
      height: 2px;
      top: 0px;
      background-color: $color;
    }

    .menu-line1 {
      position: absolute;
      width: 100%;
      height: 2px;
      top: 5px;
      background-color: $color;
    }

    .menu-line2 {
      position: absolute;
      width: 100%;
      height: 2px;
      top: 10px;
      background-color: $color;
    }

    &.sticky {
      position: absolute !important;
    }
  }

  @media only screen and (max-width: 850px) {
    &.navbar {
      .search {
        display: none;
      }

      .mainside {
        a {
          font-size: 13px;
          padding: 4px 10px;
        }
      }

      .logo {
        img {
          width: 90%;
        }
      }
    }
  }

  &.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    background: #faf6f1;
    border-bottom: 0;
    transition: all 0.6s ease;

    &.white {
      background: #faf6f1;
      box-shadow: 0 4px 20px 0 rgba(10, 10, 10, 0);
      border-bottom: solid 1px #dddddd;

      .btn {
        color: $color;
      }

      a {
        color: $color;
      }

      .mainside a {
        color: $white;

        &:hover {
          color: $color;
        }
      }
    }
  }

  .search {
    padding-top: 10px;
    width: max-content;
    padding-left: 30px;

    #quick_search {
      padding: 4px 11px;
      border: none;
      font-size: 15px;
      border: solid 1px #888;
      color: $general;
      background: #fdfbf8;
      width: 274px;
      height: 34px;
      outline: none;
      transition: 0.7s;

      &:hover {
        transition: 0.7s;
      }
    }
  }

  .mainside {
    width: max-content;
    padding-left: 0;
    padding-right: 0;

    .side-feature.con_discon button.disabled,
    .side-feature.create button.disabled {
      outline: none;
      border: none;
      color: #a3a2a2;
      opacity: 0.6;
      background-color: transparent;
      margin: 0 0 0 10px;
      white-space: nowrap;
    }

    a {
      text-align: center;
      color: #fff;
      background: $color;
      letter-spacing: normal;
      outline: 0;
      font-weight: 800;
      text-decoration: none;
      padding: 6px 20px;
      font-size: 14px;
      border: none;
      cursor: pointer;
      box-shadow: 6px 6px 0px 0px rgba(0, 0, 0, 0);
      transition: all 0.3s ease;

      &:hover {
        color: $color !important;
        background: $white;
        border: solid 1px #888;
        box-shadow: 2px 2px 20px 0px #ff343f;
        transition: all 0.3s ease;
        box-shadow: 6px 6px 0px 0px rgba(0, 0, 0, 0.09);
      }
    }
  }
}

/* disable click active */
.navbar-item {
  pointer-events: auto;
}

.navbar-item a.active {
  /*pointer-events: none;*/
}

/* disable click active */

/*** slider ***/
.jumbomain {
  position: relative;
  padding: 0;
  margin: 0;
}

.slider-wrapper {
  position: relative;
  height: 100vh;
  overflow: hidden;

  .previousButton,
  .nextButton {
    opacity: 0;
    text-decoration: none;
    transition: all 0.3s ease;
  }

  &:hover {
    .previousButton,
    .nextButton {
      opacity: 1;
      transition: all 0.3s ease;
    }
  }
}

.slide {
  background-size: cover !important;
}

.slide::before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  bottom: 0;
  left: 0;
}

.previousButton,
.nextButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  cursor: pointer;
  text-align: center;

  svg {
    display: none;
  }
}

.previousButton::before,
.nextButton::before {
  content: "\f054";
  font-family: FontAwesome;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: normal;
  color: #fff;
  opacity: 0.8;
}

.previousButton:hover,
.nextButton:hover {
}

.previousButton {
  left: 0;
  -webkit-transform: rotate(180deg) translateY(calc(50% + 0px));
  transform: rotate(180deg) translateY(calc(50% + 0px));
}

.previousButton {
  left: 4%;
}

.nextButton {
  right: 4%;
}

.slider-content {
  text-align: left;
}

.slider-content .inner {
  padding: 0 12%;
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.slider-content .inner button {
  font-weight: 500;
  position: relative;
  overflow: hidden;
  font-size: 11pt;
  letter-spacing: 1px;
  color: $white;
  border: 2px solid $color;
  background: none;
  border-radius: 0;
  padding: 0 44px;
  height: 45px;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  z-index: 0;

  span {
    position: relative;
    z-index: 1;
  }

  &::before {
    content: "";
    width: 180%;
    height: 180%;
    top: -100%;
    left: -100%;
    position: absolute;
    background-image: linear-gradient(to left, $color, $color_more);
    opacity: 0;
    z-index: 1;
    transition: all 0.3s ease;
  }

  &:hover::before {
    top: 0;
    left: 0;
    opacity: 1;
    transition: all 0.3s ease;
  }

  &:hover {
    .shine {
      -webkit-animation: sheen 0.6s alternate;
      animation: sheen 0.6s alternate;
    }
  }
}

.slider-content .inner h1 {
  font-weight: 600;
  max-width: 840px;
  color: #fff;
  font-size: 53px;
}

.slider-content .inner p {
  color: #fff;
  font-size: 40px;
  line-height: 1.3;
  max-width: 640px;
  margin-bottom: 30px;
}

.slider-content section {
  position: absolute;
  bottom: 20px;
  left: 20px;
}

.slider-content section span {
  color: #fff;
}

.slider-content section span {
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
  display: inline-block;
  text-align: left;
  line-height: 1.4;
  vertical-align: middle;
  margin-left: 10px;
}

.slider-content section img {
  width: 40px;
  height: 40px;
  border: solid 2px rgba(255, 255, 255, 0.5);
  border-radius: 100%;
  vertical-align: middle;
}

.slider-content section span strong {
  color: #fff;
  font-size: 14px;
  display: block;
}

@media (max-height: 500px) {
  .slider-wrapper,
  .slide {
    height: calc(100vh - 75px);
  }
}

@media (max-width: 640px) {
  .slider-wrapper,
  .slide {
    height: calc(80vh - 75px);
  }
}

@media (max-height: 600px) {
  .slider-content .inner h1 {
    font-size: 32px;
  }
}

@media (max-width: 640px) {
  .slider-content .inner h1 {
    font-size: 32px;
  }

  .slider-content .inner p {
    font-size: 21px;
  }
}

.slide h1 {
  transition: all 0.3s ease;
  -webkit-transform: translateY(-20px);
  transform: translateY(-20px);
  opacity: 0;
}

.slide button {
  transition: all 0.3s ease;
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
  opacity: 0;
}

.slide p {
  transition: all 0.3s ease;
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
  opacity: 0;
}

.slide section * {
  transition: all 0.3s ease;
}

.slide section img {
  -webkit-transform: translateX(-10px);
  transform: translateX(-10px);
  opacity: 0;
}

.slide section span {
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
  opacity: 0;
}

.slide section span strong {
  -webkit-transform: translateY(10px);
  transform: translateY(10px);
  opacity: 0;
}

.slide.animateIn.previous h1,
.slide.current h1,
.slide.animateIn.next h1,
.slide.animateIn.previous button,
.slide.current button,
.slide.animateIn.next button,
.slide.animateIn.previous p,
.slide.current p,
.slide.animateIn.next p,
.slide.animateIn.previous section *,
.slide.current section *,
.slide.animateIn.next section * {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition-delay: 0.9s;
  transition-delay: 0.9s;
  opacity: 1;
}

.slide.animateIn.previous p,
.slide.animateIn.next p {
  -webkit-transition-delay: 1.1s;
  transition-delay: 1.1s;
}

.slide.animateIn.previous button,
.slide.animateIn.next button {
  -webkit-transition-delay: 1.3s;
  transition-delay: 1.3s;
}

.slide.animateIn.previous section img,
.slide.animateIn.next section img {
  -webkit-transition-delay: 1.3s;
  transition-delay: 1.3s;
}

.slide.animateIn.previous section span,
.slide.animateIn.next section span {
  -webkit-transition-delay: 1.4s;
  transition-delay: 1.4s;
}

.slide.animateIn.previous section span strong,
.slide.animateIn.next section span strong {
  -webkit-transition-delay: 1.5s;
  transition-delay: 1.5s;
}

.slide.animateOut h1 {
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
}

.slide.animateOut p {
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

.slide.animateOut section span {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

.slide.animateOut section span strong {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

/*** breadcumb ***/
.breadcumb {
  border-radius: 0;
  margin: 0;
  padding: 0 0;
  background-size: cover;
  width: 100%;
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(to bottom, $black, rgba(0, 0, 0, 0.1));
    opacity: 0.5;
  }

  &.no-bg::before {
    content: unset;
  }

  .mainbreadcumb {
    padding: 20px 0 15px 0;

    .custom-container {
      max-width: 1320px !important;
      margin: 0 auto;
      width: 94% !important;
    }

    h1 {
      margin-bottom: 0;
      color: black;
      width: 100%;
      text-align: left !important;
      font-size: 30px;
    }

    .col-12 {
      width: 100%;
    }

    p {
      color: $general;
      margin-top: 15px;
    }

    .list {
      position: relative;
      bottom: -20px;
      display: flex;
      justify-content: right;
      font-size: 0.86rem;
      font-weight: 400;
      letter-spacing: 4px;

      .dash {
        padding: 0 10px;
      }

      .link {
        color: $white;
        font-weight: 500;
        line-height: 1.3;
        letter-spacing: 1px;
        margin: 0;
        padding: 0;
        text-decoration: none;
        cursor: pointer;
        transition: all 0.3s ease;

        &:hover {
          color: $color;
          transition: all 0.3s ease;
        }
      }
    }
  }

  .homebread {
    width: 100%;
    padding: 180px 0 140px;

    .heading {
      line-height: 1.3;
      font-size: 3rem;
      font-weight: 700;
      margin-bottom: 15px;
    }

    .subheading {
      font-size: 2.1rem;
      margin-bottom: 0;
    }

    .content {
      max-width: 500px;

      margin-bottom: 0;
      margin-bottom: 30px;
    }
  }

  &.h-vh {
    height: 100vh;
    display: flex;
    align-items: center;
    background-position: center;
    background-size: cover;
  }
}

/* home */
.subheading {
  font-size: 1.3rem;
  margin-bottom: 15px;
}

.heading {
  font-size: 2.3rem;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 15px;
  letter-spacing: 1px;
}

.content {
  margin-bottom: 0.5rem;
}

.col-feature {
  display: block;

  .sub-color {
    color: $color;
    font-size: 1.6rem;
    font-weight: 700;
  }

  .heading {
    font-size: 1.1rem;
    font-weight: 500;
    margin: 5px 0;
  }

  .content {
  }
}

.border-radius {
  border-radius: 0px;
  box-shadow: 0 10px 12px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  transition: all 0.3s ease;

  &:hover {
    box-shadow: none;
    transition: all 0.3s ease;
  }
}

/* icon scroll */
.icon-scroll-wraper {
  position: absolute;
  width: 100%;
  bottom: 5vh;
  left: 0;
}

.icon-scroll {
  position: relative;
  margin: 0 auto;
}

.icon-scroll:before {
  position: absolute;
}

.icon-scroll {
  width: 26px;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.3);
  z-index: 1;
  border-radius: 25px;
  height: 38px;
}

.icon-scroll:before {
  content: "";
  width: 3px;
  height: 6px;
  background: rgba(255, 255, 255, 0.6);
  left: 12px;
  top: 8px;
  border-radius: 4px;
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: scroll;
  animation-name: scroll;
}

@-webkit-keyframes scroll {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }
}

@keyframes scroll {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }
}

.features {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 60px;
  overflow: hidden;

  .bg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .content {
    position: relative;
    text-align: center;

    .heading {
      color: $color;
      font-weight: 500;
      font-size: 1.2rem;
      margin-bottom: 10px;
    }

    .con-text {
      font-size: 0.9rem;
    }

    .link {
      width: max-content;
      font-weight: 500;
      position: relative;
      overflow: hidden;
      font-size: 11pt;
      letter-spacing: 1px;
      color: #fff;
      border: 2px solid $color;
      background: none;
      border-radius: 0;
      padding: 0 44px;
      height: 45px;
      line-height: 40px;
      margin: 20px auto 0;
      cursor: pointer;
      transition: all 0.3s ease;

      &:hover {
        background: $color;
        border: 2px solid rgba(0, 0, 0, 0);
        transition: all 0.3s ease;

        .shine {
          -webkit-animation: sheen 0.6s alternate;
          animation: sheen 0.6s alternate;
        }
      }
    }
  }
}

/*** footer ***/
footer {
  padding: 80px 0 0 0;
  background: #8364e2;

  &.footer-light {
    border-top: solid 1px rgba(255, 255, 255, 0.025);
    color: $white;

    a {
      color: $white;
      font-weight: 400;
      text-decoration: none !important;

      &:hover {
        color: $white;
      }
    }

    #form_subscribe {
      input[type="text"] {
        width: 80%;
        float: left;
        border-radius: 2px 0 0 2px;
        color: #ffffff;
        background: rgba(255, 255, 255, 0.1);
        border: solid 1px #333333;
        border-right-color: rgb(51, 51, 51);
        border-right-style: solid;
        border-right-width: 1px;
        border-right: none;
        padding: 6px 12px;
        margin-bottom: 0;
        box-shadow: 2px 2px 20px 0px rgba(20, 20, 20, 0.05);
      }

      #btn-subscribe i {
        text-align: center;
        font-size: 28px;
        float: left;
        width: 20%;
        background: none;
        color: #ffffff;
        display: table-cell;
        padding: 5px 0 5px 0;
        border-radius: 0 30px 30px 0;
      }
    }

    .subfooter {
      margin-top: 40px;
      padding: 20px 0 20px 0;
      border-top: solid 1px rgba(255, 255, 255, 0.1);

      span {
        cursor: pointer;

        img {
          margin-right: 30px;

          &.f-logo.d-1 {
            display: inline-block;
          }

          &.f-logo.d-3 {
            display: none;
          }
        }

        &.copy {
          cursor: default;
          margin: 0;
        }
      }

      .social-icons {
        display: inline-block;

        span {
          color: #595d69;

          i {
            text-shadow: none;
            color: $white;
            background: none;
            padding: 12px 10px 8px 10px;
            width: 34px;
            height: 34px;
            text-align: center;
            font-size: 16px;
            border-radius: 5px;
            margin: 0 5px 0 5px;
            transition: 0.3s;
          }

          &:hover i {
            color: $black;
            background: $white;
            transition: 0.3s;
          }
        }
      }
    }
  }

  .widget {
    margin-bottom: 30px;
    padding-bottom: 30px;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        margin: 5px 0;
      }
    }

    h5 {
      margin-bottom: 20px;
      color: $white;
    }
  }
}

#scroll-to-top {
  cursor: pointer;
  width: max-content;
  height: max-content;
  position: fixed;
  right: 10px;
  z-index: 999;

  &.init {
    bottom: -60px;
    transition: all 0.3s ease;
  }

  &.show {
    bottom: 15px;
    transition: all 0.3s ease;
  }

  div {
    font-size: 0.8rem;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    border-radius: 30px;
    background: #0d0c22;
    transition: all 0.3s ease;

    &:hover {
      transform: scale(1.1);
      transition: all 0.3s ease;
    }

    i {
      font-style: normal;

      &:before {
        font-family: "FontAwesome";
        font-size: 18px;
        content: "\f106";
        color: #fff !important;
        position: relative;
      }
    }
  }
}

/** portofolio **/
.AppContainer {
  color: $white;
  position: relative;
  z-index: 99;
  height: 100%;
  width: 100%;
  background: none;

  .gallery-container {
    .react-photo-gallery--gallery {
    }
  }
}

.react-photo-gallery--gallery {
  margin-top: 15px;
}

.lightbox-portal {
  position: relative;
  z-index: 999;

  h4 {
    font-weight: 700;
  }
}

.btnLright {
  color: $white;
  font-size: 21px !important;
  right: 10px !important;
}

.btnLleft {
  color: $white;
  font-size: 21px !important;
  left: 10px !important;
}

.closeL {
  font-size: 21px !important;
  position: absolute;
  top: 10px;
  right: 10px;
}

.ConMainGimg {
  border-radius: 5px;
  overflow: hidden;
}

.MainGimg {
  transform: scale(1);
  transition: 0.7s;

  .overlayCap {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    font-size: 1rem;
    width: 100%;
    bottom: 15px;
    font-weight: 500;
    color: $white;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.7s;
    outline: none;
    z-index: 1;

    &:before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: $color_more;
      z-index: 0;
      opacity: 0.8;
    }

    span {
      position: relative;
      z-index: 2;
    }
  }

  &:hover {
    transform: scale(1.1);
    box-shadow: 0 10px 12px 0 rgba(0, 0, 0, 0.2),
      0 6px 20px 0 rgba(0, 0, 0, 0.19);
    transition: 0.7s;
    z-index: 2;

    .overlayCap {
      opacity: 1;
    }
  }
}

/** contact **/
.form-side {
  border-radius: 4px;
}

.text-side {
  padding: 15px 0;
  margin-top: 15px;

  .address {
    padding-top: 15px;
    line-height: 1.6;

    .heading {
      font-size: 1.4rem;
      font-weight: 500;
      letter-spacing: 1px;
    }

    .list {
      position: relative;
      max-width: 320px;
      display: block;
      padding: 5px 0 15px;
      padding-left: 40px;

      i {
        position: absolute;
        top: 5px;
        left: 5px;
        font-size: 1.3rem;
        display: inline-block;
      }

      a {
        color: $white;
        text-decoration: none;
        transition: all 0.3s ease;

        &:hover {
          color: $color;
          transition: all 0.3s ease;
        }
      }
    }
  }
}

.formcontact {
  display: block;
  width: 100%;

  label {
    display: block;
    width: 100%;
    margin-bottom: 5px;
  }

  input {
    width: 100%;
    font-size: 0.9rem;
    width: 100%;
    padding: 8px 10px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 15px;
  }

  textarea {
    font-size: 0.9rem;
    width: 100%;
    height: 195px;
    padding: 8px 10px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 15px;
  }

  .msg-error {
    font-size: 0.87rem;
    opacity: 0.8;
    padding: 5px 0;
  }

  button {
    position: relative;
    overflow: hidden;
    font-size: 11pt;
    color: $white;
    background: none;
    border: 2px solid $color;
    border-radius: 0;
    padding: 8px 50px;
    margin-top: 15px;
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
    z-index: 0;

    span {
      color: $white;
      position: relative;
      z-index: 3;
    }

    &::before {
      content: "";
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      position: absolute;
      background-image: linear-gradient(to left, $color, $color_more);
      opacity: 0;
      z-index: 1;
      transition: all 0.3s ease;
    }

    &:hover::before {
      opacity: 1;
      transition: all 0.3s ease;
    }

    &:hover {
      .shine {
        -webkit-animation: sheen 1s alternate;
        animation: sheen 1s alternate;
      }
    }
  }
}

#success,
#failed {
  &.show {
    display: block !important;
    margin-bottom: 15px;
  }

  &.hide {
    display: none;
  }
}

#buttonsent.show {
  pointer-events: none;
}

#buttonsent {
  width: max-content;
  padding: 8px 10px;
}

.padding40.box-rounded.mb30 {
  padding: 40px;
  border-radius: 3px;
  background-color: rgba(255, 255, 255, 0.05);

  &.text-light {
    color: $white;
    background: rgba(255, 255, 255, 0.05);
    margin-bottom: 0;

    h3 {
      color: $color;
    }

    .btn {
      color: $color;
    }
  }
}

address {
  margin-top: 20px;
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;

  &.s1 span {
    display: block;
    padding: 0;
    color: $color;
    font-size: 15px;
    line-height: 1.7em;
    margin-bottom: 5px;

    i {
      color: $color;
      margin-top: 5px;
      margin-left: 5px;
      margin-right: 15px;
      font-size: 15px;
    }

    .btn {
      display: inline-block;
      border: 0;
      color: $color;
      padding: 0;
      margin: 0;

      &:hover {
        background: none;
        padding: 0;
      }

      &:before {
        content: unset;
      }
    }
  }
}

/*** news ***/
.post-content {
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 20px;
}

.post-image {
  position: relative;

  img {
    width: 100%;
    margin-bottom: 0px;
  }
}

.post-text {
  padding-top: 20px;

  .p-tagline,
  .p-title {
    background: $color;
    color: $white;
    position: absolute;
    top: 20px;
    left: 20px;
    border-radius: 0;
    display: inline-block;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 2px;
    line-height: 10px;
    padding: 7px 10px 6px 10px;
    text-transform: uppercase;
    margin-bottom: 5px;
  }

  .p-date {
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 12px;
    font-weight: 500;
  }

  h4 {
    margin-top: 10px;
    margin-bottom: 10px;

    span {
      color: $color;
      text-decoration: none;
      font-size: 22px;
      font-weight: bold;
    }
  }
}

.pagination {
  margin: 0 auto;
  font-weight: 500;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.pagination li .a {
  font-size: 14px;
  color: $color;
  border: solid 1px rgba(255, 255, 255, 0.1);
  border-right: none;
  background: none;
  padding: 15px 20px 15px 20px;
  border-radius: 0;
  cursor: pointer;
}

.dark-scheme .pagination li .a {
  border-color: rgba(255, 255, 255, 0);
  background: #21273e;
}

.pagination li:last-child .a {
  border-right: solid 1px rgba(255, 255, 255, 0.1);
}

.dark-scheme .pagination li:last-child .a {
  border-right-color: rgba(255, 255, 255, 0.1);
}

.pagination > .active > .a {
  color: #ffffff;
  background: $color;
}

.c-nft-info {
  font-family: familjenGrotesk;
  color: #494949;
  font-weight: 300;
}

/*** create file ***/
.d-create-file {
  padding: 20px;
  border-radius: 0;
  border: 1.5px solid #dddddd;
  background-color: white;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 1200px) {
    flex-direction: column;
    justify-content: center;
  }

  #file_name {
    margin-bottom: 0;
  }

  #get_file {
    margin: 0 auto;
  }

  .browse {
    position: relative;
    width: max-content;
    height: auto;

    @media screen and (max-width: 1200px) {
      margin-top: 6px;
    }

    #upload_file, #upload_metaData {
      visibility: hidden;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
    }

    &:hover {
      .btn-main {
        box-shadow: 2px 2px 20px 0px #ff343f;
        transition: all 0.3s ease;
      }
    }
  }
}

/*** activity ***/
.filter__l {
  font-weight: bold;
}

.filter__r {
  float: right;
  cursor: pointer;
}

.activity-filter {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    display: inline-block;
    padding: 8px 10px;
    border: solid 1px #888;
    background: #fdfbf8;
    border-radius: 0;
    margin-right: 5px;
    margin-bottom: 10px;
    width: 48%;
    font-weight: bold;
    cursor: pointer;

    i {
      height: 32px;
      width: 32px;
      padding: 9px 0;
      text-align: center;
      border-radius: 100%;
      background: rgba(255, 255, 255, 0.01);
      color: $color;
      margin-right: 10px;
    }
  }
}

.activity-list {
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    padding: 20px;
    padding-right: 20px;
    border-radius: 0;
    margin-bottom: 20px;
    position: relative;
    padding-right: 100px;
    border: solid 1px #888;
    background: #fdfbf8;
    box-shadow: 6px 6px 0px 0px rgba(0, 0, 0, 0.09);

    img {
      width: 80px;
      position: absolute;
      border-radius: 100%;
    }

    &.act_sale {
      img {
        border-radius: 5px;
      }

      &::after {
        content: "\f291";
      }
    }

    &.act_un_like {
      img {
        border-radius: 5px;
      }

      &::after {
        content: "\1F494";
      }
    }

    &.act_like {
      img {
        border-radius: 5px;
      }

      &::after {
        content: "\f004";
      }
    }

    &.act_login {
      img {
        border-radius: 5px;
      }

      &::after {
        content: "\1F511";
      }
    }

    &.act_offer {
      img {
        border-radius: 5px;
      }

      &::after {
        content: "\f0e3";
      }
    }

    .act_list_text {
      padding-left: 100px;

      .act_list_date {
        display: block;
      }

      h4 {
        font-size: 16px;
        margin-top: 0;
        margin-bottom: 10px;
      }
    }

    &:after {
      content: "\f00c";
      font-family: "FontAwesome";
      margin-right: 15px;
      float: right;
      position: absolute;
      right: 20px;
      top: 30%;
      font-size: 26px;
      color: $color;
      background: rgba(255, 255, 255, 0.025);
      border-top: 1px solid rgba(255, 255, 255, 0.1);
      box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.3);
      height: 48px;
      width: 48px;
      padding: 6px 0;
      text-align: center;
      border-radius: 100%;
    }
  }
}

#sale.active,
#follow.active,
#offer.active,
#like.active {
  background: $color !important;
  color: $white;
}

/*** feature box ***/
$color: #212428;
$white: #fff;
$border-radius: 3px;

/*** feature box ***/
.feature-box {
  &.inner {
    position: relative;
    overflow: hidden;
    padding: 40px;
    border-radius: $border-radius;
  }

  &.s1 .inner {
    background: $color;
  }

  &.s1:hover .inner i {
    background: none;
    color: #303030;
  }

  .text-light & .inner {
    color: $white;
  }

  i {
    font-size: 40px;
    margin-bottom: 20px;
  }

  &.left i {
    position: absolute;
  }

  &.left .text {
    padding-left: 70px;
  }

  &.center {
    text-align: center;
  }

  i.circle,
  i.square {
    display: inline-block;
    padding: 20px;
    font-size: 40px;
    font-weight: 500;
    color: $white;
    width: 80px;
    height: 80px;
    border-radius: 60px;
    -moz-border-radius: 60px;
    -webkit-border-radius: 60px;
    position: relative;
    z-index: 2;
  }

  i.square {
    border-radius: 0px;
    -moz-border-radius: 0px;
    -webkit-border-radius: 0px;
  }

  i.circle {
    display: inline-block;
    padding: 20px;
    font-size: 40px;
    font-weight: 500;
    color: $white;
    width: 80px;
    height: 80px;
    border-radius: 60px;
    -moz-border-radius: 60px;
    -webkit-border-radius: 60px;
    position: relative;
    z-index: 2;
  }

  i.font60 {
    font-size: 60px;
  }
  h4 {
    line-height: normal;
    margin-bottom: 5px;
    font-size: 24px; // Add this line
  }
  

  i.wm {
    font-size: 800px;
    position: absolute;
    top: 0%;
    width: 100%;
    left: 50%;
    transition: 2s ease;

    &:hover {
      transition: 2s ease;
    }
  }

  &:hover .inner i.wm {
    transform: scale(1.2);
    color: rgba(0, 0, 0, 0.05);
    z-index: 0;
  }

  &:hover .inner i.circle {
    -webkit-box-shadow: 5px 5px 40px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 5px 5px 40px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 5px 5px 40px 0px rgba(0, 0, 0, 0.3);
  }

  &.style-3 {
    position: relative;
    overflow: hidden !important;
    
    i {
      display: block;
      text-align: center;
      font-size: 28px;
      color: $white;
      background: black;
      padding: 14px 0;
      width: 56px;
      height: 56px;
      border-radius: 0;
    }
    
    i.wm {
      position: absolute;
      font-size: 240px;
      background: none;
      width: auto;
      height: auto;
      color: rgba(13, 12, 34, 0.1);
      right: 0;
      top: 30%;
    }
    
    &:hover i.wm {
      -webkit-transform: rotate(-25deg);
      -moz-transform: rotate(-25deg);
      -o-transform: rotate(-25deg);
      transform: rotate(-25deg);
    }
  }
  
  &.style-4 {
    i {
      position: relative;
      z-index: 2;
      padding: 10px;
      min-width: 60px;
    }

    .wm {
      display: block;
      position: absolute;
      font-size: 160px;
      background: none;
      width: 100%;
      height: auto;
      right: 0;
      top: 120px;
      z-index: 2;
      font-weight: bold;
    }

    .text {
      margin-top: -50px;
      position: relative;
      z-index: 1;
      background: $white;
      padding: 30px;
      padding-top: 50px;
    }
  }
  
  &.f-boxed {
    padding: 50px 30px;
    background: rgba(13, 12, 34, 0.1);
    border-radius: 0;
    transition: 0.7s;
    height: 100%;
    display: flex;
    flex-direction: column;

    .text {
      flex: 1;
      display: flex;
      flex-direction: column;

      > div:last-child {
        margin-top: auto;
      }
    }

    &:hover {
      background: $color;
      color: $white;
      transition: 0.7s;
    }
  }
  
  .text-light &.f-boxed {
    background: rgba(0, 0, 0, 0.1);
  }
  
  &.f-boxed:hover {
    color: $white;
    -webkit-box-shadow: 2px 2px 20px 0px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 2px 2px 20px 0px rgba(0, 0, 0, 0.05);
    box-shadow: 2px 2px 20px 0px rgba(0, 0, 0, 0.05);
  }
  
  &.f-boxed:hover h4 {
    color: $white;
  }

  i.i-circle {
    border-radius: 60px;
  }

  i.i-boxed {
    border-radius: 0;
  }

  &.small-icon {
    &.center {
      text-align: center;
    }

    .dark & .inner:hover > i {
      color: $white;
    }

    .text {
      padding-left: 80px;
    }

    &.center .text {
      padding-left: 0;
      display: block;
    }

    i {
      text-shadow: none;
      font-size: 40px;
      width: 68px;
      height: 68px;
      text-align: center;
      position: absolute;
      text-align: center;
    }

    i.hover {
      color: #333;
    }

    .border {
      height: 2px;
      width: 30px;
      background: #ccc;
      display: block;
      margin-top: 20px;
      margin-left: 85px;
    }

    .btn {
      margin-top: 10px;
    }

    &.center i {
      position: inherit;
      float: none;
      display: inline-block;
      margin-bottom: 20px;
      border: none;
      font-size: 60px;
      background: none;
      padding: 0px;
    }

    &.center .fs1 {
      position: inherit;
      float: none;
      display: inline-block;
      margin-bottom: 20px;
      border: none;
      font-size: 60px;
      background: none;
      padding: 0px;
    }

    i.dark {
      background-color: #1a1c26;
    }

    h4 {
      line-height: normal;
      margin-bottom: 5px;
    }

    &.no-bg h2 {
      padding-top: 12px;
    }

    .number {
      font-size: 40px;
      text-align: center;
      position: absolute;
      width: 70px;
      margin-top: -5px;
    }

    .number.circle {
      border-bottom: solid 2px rgba(255, 255, 255, 0.3);
      padding: 20px;
      width: 70px;
      height: 70px;
    }

    &.style-2 .number {
      font-size: 32px;
      color: $white;
      padding-top: 22px;
      background: #253545;
      width: 70px;
      height: 70px;
    }

    &.no-bg .inner,
    &.no-bg .inner:hover {
      padding: 0;
      background: none;
    }
  }
}


/*** column nft ***/
.nft_coll {
  height: 324px;
  padding-bottom: 10px;
  border-radius: 0px;
  border: solid 1px #888;
  background: #fdfbf8;
  box-shadow: 6px 6px 0px 0px rgba(0, 0, 0, 0.09);
  margin: 10px;

  img {
    transition: 0.7s;
  }

  &:hover {
    img {
      transform: scale(1.05);
      transition: 0.7s;
    }
  }
}

.nft_wrap {
  position: relative;
  width: 90%;
  height: 170px;
  overflow: hidden;
  border-radius: 0;
  margin: 10px auto;
  text-align: center;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: center;
}

.nft_coll_pp {
  width: 60px;
  display: block;
  margin: 0 auto;
  margin-top: -30px;
  margin-bottom: 10px;
  position: relative;

  div {
    width: 60px;
    height: 60px;
    overflow: hidden;
    border-radius: 50%;
  }

  span {
    cursor: pointer;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  i {
    font-size: 12px;
    color: #ffffff;
    background: $color;
    border-radius: 100%;
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
    padding: 3px;
    position: absolute;
    bottom: 4px;
    right: 5px;
  }
}

.nft_coll_info {
  text-align: center;
  padding-bottom: 10px;

  span {
    cursor: pointer;
  }
}

.nft_coll h4 {
  font-size: 16px;
  margin-bottom: 0px;
}

.nft_coll span {
  font-size: 14px;
}

.nft_coll p {
  margin-top: 10px;
}

.nft_coll_by {
  font-weight: bold;
}

.nft {
  width: 100%;

  .slick-prev {
    left: -12px;
    background: #212428;
    border-radius: 45px;
    display: block;
    width: 45px;
    height: 45px;
    line-height: 45px;
    z-index: 1;
    transition: all 0.3s ease;

    &::before {
      content: "\f053" !important;
      font-size: 1rem;
      font-family: FontAwesome !important;
      font-style: normal;
      font-weight: normal;
      color: $white;
      background: #212428;
      border: 1px solid rgba(255, 255, 255, 0.1);
      border-radius: 45px;
      display: block;
      width: 45px;
      height: 45px;
      line-height: 45px;
    }

    &:hover {
      background: $white;
      box-shadow: 5px 5px 30px 0px rgba(0, 0, 0, 0.2);
    }
  }

  .slick-next {
    right: -12px;
    background: #212428;
    border-radius: 45px;
    display: block;
    width: 45px;
    height: 45px;
    line-height: 45px;
    transition: all 0.3s ease;

    &::before {
      content: "\f054" !important;
      font-size: 1rem;
      font-family: FontAwesome !important;
      font-style: normal;
      font-weight: normal;
      color: $white;
      background: #212428;
      border: 1px solid rgba(255, 255, 255, 0.1);
      border-radius: 45px;
      display: block;
      width: 45px;
      height: 45px;
      line-height: 45px;
    }

    &:hover {
      background: $white;
      box-shadow: 5px 5px 30px 0px rgba(0, 0, 0, 0.2);
    }
  }

  &:hover {
    .slick-prev,
    .slick-next {
      opacity: 1;
      transition: all 0.3s ease;
    }
  }

  .blockquote {
    font-size: 0.98rem;
    text-align: left;
    margin: 15px 30px 30px;
    display: flex;

    .fa {
      font-size: 1.3rem;
      display: inline-block;
      margin: 5px 15px 0 0;
      color: $white;
      height: max-content;
    }

    p {
      line-height: 1.8;
      display: inline-block;
    }

    .bytesti {
      font-size: 0.9rem;
      font-weight: 400;
    }
  }

  .slick-dots {
    bottom: -40px;

    li.slick-active button::before {
      opacity: 0.75;
      color: $white;
    }

    li button::before {
      color: $white;
    }
  }
}

.nft__item {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0px;
  margin: 10px;
  border: solid 1px lightgray;
  background: white;
  border-radius: 0;
  margin-bottom: 25px;
  transition: 0.7s;
  border-radius: 5px;
  height: 100%;

  &:hover {
    transition: 0.7s;
  }
}

.nft__item_info {
  cursor: pointer;
  line-height: 28px;
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  word-wrap: break-word;
 button{
  min-width: 30px;
 }
  a {
    text-decoration: none;
  }
  h5{
    font-size: 28px;
    font-weight: normal;
  }
}

.nft__item h4 {
  font-weight: 400;
  font-size: 28px;
  margin-bottom: 5px;
}

.nft__item_price {
  font-size: 14px;
  font-weight: 600;
  display: block;
}

.nft__item_price span {
  margin-left: 10px;
  color: $color;
  font-weight: 700;
  @media (max-width: 640px) {
   &.price-usd{
    display: none;
    }
  }
}

.nft__item_action {
  font-size: 14px;

  span {
    font-weight: bold;
    color: $color;
  }
}

.nft__item_like {
  position: absolute;
  font-size: 20px;
  color: red;
  right: 5px;
  cursor: pointer;
  top: 5px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  z-index: 1;

  span {
    color: $gray;
    font-size: 14px;
    line-height: 14px;
  }

  &:hover i {
    color: pink;
  }

  & .liked {
    color: red;
  }
}

.ml-custom {
  padding-left: 3rem;

  .nft__item {
    height: auto;
  }
}

/*** countdown ***/
.de_count {
  h3 {
    font-size: 2rem;
    margin-bottom: 0;
  }

  h5 {
    font-size: 14px;
    font-weight: 500;
  }
}

.de_countdown {
  position: relative;
  font-size: 0.8rem;
  position: absolute;
  left: 20px;
  background: #faf6f1;
  padding: 6px 10px;
  border-radius: 0;
  border: solid 1px $color;
  z-index: 1;
  color: $color;

  .Clock-days {
    font-weight: bold;
    display: inline-block;
    margin-right: 5px;
  }

  .Clock-hours {
    font-weight: bold;
    display: inline-block;
    margin-right: 5px;
  }

  .Clock-minutes {
    font-weight: bold;
    display: inline-block;
    margin-right: 5px;
  }

  .Clock-seconds {
    font-weight: bold;
    display: inline-block;
    margin-right: 5px;
  }
}

.author-wrap {
  display: flex;
  align-items: center;
}

.author_list_pp {
  // position: relative;
  // cursor: pointer;
  // display: inline-block;
  // width: 100%;
  // height: auto;
  // background: $color;
  // border: 1px solid lightgray;
  // border-radius: 100% !important;
  z-index: 1;
  transition: 0.3s;

  &:not(.no-hover):hover img {
    padding: 3px;
    -webkit-box-shadow: 0px 0px 0px 2px $color;
    transition: 0.3s;
  }
}

.author_list_pp a {
  width: 50px;
}

.author_list_pp img {
  border-radius: 100% !important;
  -moz-border-radius: 100% !important;
  -webkit-border-radius: 100% !important;
  position: relative;
  z-index: 1;
  height: 30px;
  width: 30px;
  object-fit: cover;
  object-position: top;
}

.author_list_pp i {
  color: #ffffff;
  background: $color;
  font-size: 12px;
  padding: 3px;
  position: absolute;
  right: 0;
  bottom: 0;
  border-radius: 100%;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  z-index: 2;
}

.nft__item_wrap {
  margin-bottom: 20px;
  height: 230px;
  @media (max-width: 768px) {
      height: 175px;
  }
}


.nft__item .nft__item_preview {
  cursor: pointer;
  width: 100%;
  max-height: 200px;
  object-fit: cover;
  height: 200px;

  @media (max-width: 768px) {
      height: 175px;
}
}

.custom-video-player {
  display: flex;
  justify-content: center;
  height: 230px;

  @media (max-width: 768px) {
    height: 175px;
  }
}

/*** author list ***/
.author_list {
  column-count: 4;
  column-gap: 20px;

  li {
    margin-bottom: 30px;
  }

  .author_list_pp {
    position: absolute;
    display: inline-block;
    width: 100%;
    border: 1px solid black !important;
    height: auto;
    margin-left: 10px;
    margin-top: -3px;
    cursor: pointer;
    z-index: 10;

    &:hover {
      img {
        padding: 1px;
        background: $gray;
        box-shadow: 0px 0px 0px 4px $color;
        transition: 0.2s ease;
      }
    }

    img {
      transition: 0.2s ease;
    }
  }

  .author_list_pp img {
    width: 50px;
  }

  .author_list_info {
    font-weight: bold;
    padding-left: 70px;

    span {
      cursor: pointer;
      color: $color;
    }

    .bot {
      color: $general;
      display: block;
      font-size: 14px;
      font-weight: 400;
      line-height: 1.2em;
    }
  }
}

/*** icon box ***/
.icon-box {
  display: block;
  text-align: center;
  border: solid 1px #dddddd;
  padding: 20px 0 20px 0;
  text-decoration: none;
  cursor: pointer;
}

.icon-box.style-2 {
  border: none;
  background: rgba(133, 100, 226, 0.1);
}

.text-light .icon-box {
  border: solid 2px rgba(255, 255, 255, 0.1);
}

.icon-box i {
  display: block;
  font-size: 40px;
  margin-bottom: 10px;
  transition: 0.3s;
  color: $color;
}

.icon-box span {
  display: block;
  color: #606060;
  font-weight: bold;
  font-size: 14px;
  margin-top: 5px;
  line-height: 1.4em;
}

.dark-scheme .icon-box span {
  color: #ffffff;
}

.text-light .icon-box span {
  color: #ffffff;
}

.icon-box:hover {
  color: #ffffff;
  background: $color_more;
  -webkit-box-shadow: 5px 5px 30px 0px $color_more;
  -moz-box-shadow: 5px 5px 30px 0px $color_more;
  box-shadow: 5px 5px 30px 0px $color_more;
  transition: 0.3s;

  span,
  i {
    color: $white;
  }
}

#tsparticles {
  position: absolute;
  width: 100%;
  height: 100%;
}

/*** carousel ***/
.d-carousel {
  position: relative;
  display: flex;
  align-items: center;
}

.nft_pic {
  overflow: hidden;
  border-radius: 0px;
  position: relative;
  margin: 0 60px 10px;
  transition: 0.7s;
  cursor: pointer;

  img {
    // width: 100%;
    // height: 100%;
    width: 540px;
    height: 350px;
    object-fit: cover;
    transition: 1s;
  }

  &:hover {
    img {
      transition: 3s;
      transform: scale(1.2);
    }
  }
}

.nft_pic_info {
  position: absolute;
  background: rgba(0, 0, 0, 0.2);
  // width: 100%;
  // height: 100%;
  width: 540px;
  height: 350px;
  padding: 40px;
  z-index: 1;
  font-size: 28px;
  color: #ffffff;
}

.nft_pic_title {
  font-family: teko;
  display: block;
  font-weight: bold;
}

.nft_pic_by {
  display: block;
  font-size: 16px;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.6);
}

.nft-big {
  width: 100%;

  .slick-prev {
    left: 10px;
    display: block;
    width: 40px;
    height: 60px;
    overflow: hidden;
    line-height: 60px;
    z-index: 1;
    transition: all 0.3s ease;

    &::before {
      display: block;
      content: "\f053" !important;
      font-size: 0.9rem;
      font-family: FontAwesome !important;
      font-style: normal;
      font-weight: normal;
      color: $black;
      position: relative;
      line-height: 60px;
      width: 60px;
      height: 60px;
      padding-left: 20px;
      border-radius: 60px;
      background: $white;
      left: -25px;
      opacity: 1;
    }
  }

  .slick-next {
    right: 9px;
    display: block;
    width: 40px;
    height: 60px;
    line-height: 60px;
    overflow: hidden;
    transition: all 0.3s ease;

    &::before {
      display: block;
      content: "\f054" !important;
      font-size: 0.9rem;
      font-family: FontAwesome !important;
      font-style: normal;
      font-weight: normal;
      color: $black;
      position: relative;
      line-height: 60px;
      width: 60px;
      height: 60px;
      padding-right: 20px;
      border-radius: 60px;
      background: $white;
      right: -4px;
      opacity: 1;
    }
  }

  &:hover {
    .slick-prev,
    .slick-next {
      opacity: 1;
      transition: all 0.3s ease;
    }
  }

  .blockquote {
    font-size: 0.98rem;
    text-align: left;
    margin: 15px 30px 30px;
    display: flex;

    .fa {
      font-size: 1.3rem;
      display: inline-block;
      margin: 5px 15px 0 0;
      color: $white;
      height: max-content;
    }

    p {
      line-height: 1.8;
      display: inline-block;
    }

    .bytesti {
      font-size: 0.9rem;
      font-weight: 400;
    }
  }

  .slick-dots {
    bottom: -40px;

    li.slick-active button::before {
      opacity: 0.75;
      color: $white;
    }

    li button::before {
      color: $white;
    }
  }
}

/*** filter ***/
.items_filter {
  width: 100%;
  font-size: 14px;
  margin-bottom: 30px;
  margin-top: -14px;

  .dropdownSelect {
    display: inline-block;
    width: 200px;
    position: relative;
    margin-right: 10px;
    margin-bottom: 5px;
    z-index: 2;

    .css-1okebmr-indicatorSeparator {
      background: none;
    }

    &.one {
      z-index: 9;
    }

    &.two {
      z-index: 8;
    }

    &.three {
      z-index: 7;
    }
  }

  &.centerEl {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
  }
}

#form_quick_search {
  display: inline-block;
  margin-right: 0px;
  position: relative;
  top: 14px;

  input[type="text"] {
    padding: 8px 12px;
    width: 200px;
    float: left;
    color: $white;
    border-radius: 5px 0 0 5px;
    border: solid 1px rgba(255, 255, 255, 0.1);
    border-right-color: rgb(221, 221, 221);
    border-right-style: solid;
    border-right-width: 1px;
    border-right: none;
    background: none;
    box-shadow: 2px 2px 20px 0px rgba(20, 20, 20, 0.05);
    font-size: 16px;
  }

  #btn-submit {
    padding: 0;
    margin: 0;
    border: 0;
    background: none;

    i {
      text-align: center;
      font-size: 16px;
      float: left;
      width: 60px;
      background: $color;
      color: #ffffff;
      display: table-cell;
      padding: 13px 0;
      border-radius: 0 5px 5px 0;
      cursor: pointer;
    }
  }
}

.select1 {
  .css-wenqhu-control {
    background-color: #212428;
    border: 1px solid rgba(255, 255, 255, 0.1);

    & > div > div {
      color: $white;
    }
  }
}

/*** colection ***/
.profile_banner {
  height: 400px;
  background-position: center;
  background-size: 100%;
  z-index: -1;

  &.no_index {
    z-index: unset;
  }
}

.d-profile-img-edit {
  cursor: pointer;
}

.profile_banner.edit {
  height: 360px;
  background-position: center;
}

.profile_banner.coll_banner {
  height: 300px;
  background-position: center;

  h4 {
    color: white;
    font-weight: 400;
    font-size: 35px;
    padding-top: 8px;
  }
}

.coll_profile {
  margin-top: 8px;
  align-items: center;
  flex-wrap: wrap;

  .my_profile_avatar {
    width: 50px !important;
    height: 50px !important;
  }

  .sing-coll-links {
    a {
      display: block;
    }
  }
}

.d_coll {
  position: relative;
}

.d_profile {
  margin-bottom: 40px;
}

.profile_avatar {
  display: flex;
}

.d_coll .profile_avatar {
  display: block;
  text-align: center;
}

.profile_avatar img,
.profile_name {
  display: flex;
  align-items: center;

  .profile_username {
    font-size: 16px;
    display: block;
    color: $color;
  }
}

.d_coll .profile_avatar img,
.d_coll .profile_name {
  display: block;
  margin: 0 auto;
  text-align: center;
}

.profile_avatar img {
  border-radius: 100%;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  width: 150px;
  height: auto;
}

.d_coll .profile_avatar img {
  display: inline-block;
  margin-top: -100px;
  margin-bottom: 30px;
}

.profile_avatar i {
  color: #ffffff;
  background: $color;
  margin: 110px 0 0 110px;
  display: block;
  position: absolute;
  padding: 10px;
  border-radius: 100%;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
}

.d_coll .profile_avatar i {
  margin-top: -30px;
}

.d_coll .d_profile_img {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.profile_name {
  margin-left: 20px;
}

.profile_name h4 {
  font-size: 24px;
  line-height: 1.3em;
}

.profile_wallet {
  font-size: 16px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
  overflow: hidden;
  float: left;
  color: #727272;
}

.d_coll .profile_wallet {
  display: inline-block;
  float: none;
}

.btn_copy {
  font-size: 14px;
  padding: 4px 10px;
  line-height: 1em;
  border: solid 1px #dddddd;
  display: inline-block;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  outline: none;

  &:hover {
    color: $white;
    background: $color;
    border: 0;
  }
}

.de_nav {
  overflow: hidden;
  padding-left: 0;
  margin: 0;
  padding: 0;
  text-align: center;
  font-weight: bold;

  &.text-left {
    text-align: left;
    margin: 10px;
  }

  li {
    float: none;
    display: inline-block;
    margin-right: 5px;

    span {
      position: relative;
      padding: 10px 20px;
      margin-right: 5px;
      display: block;
      background: $white;
      text-decoration: none;
      color: $color;
      border: 1px solid rgba(0, 0, 0, 0.3);
      font-size: 15px;
      cursor: pointer;
      border-radius: 0;

      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    &.active span {
      color: $white;
      background: $color;
    }
  }
}

.profile_follower {
  margin-right: 20px;
  font-weight: 600;
}

/*** item details ***/
.item_info {
  .item_info_like {
    cursor: pointer;

    & .liked {
      color: red;
    }

    & :hover {
      color: pink;
    }
  }

  // padding-left: 10px;

  .de_countdown {
    position: relative;
    display: inline-block;
    margin: 0;
    margin-bottom: 0px;
    margin-left: 0px;
    right: 0;
    left: 0;
    border: none;
    padding: 0;
    margin-bottom: 10px;
  }

  .item_info_counts {
    display: flex;
    align-items: stretch;
    margin-bottom: 20px;

    > div {
      margin-right: 10px;
      font-weight: 400;
      padding: 2px 10px;
      font-size: 14px;
      text-align: center;
      min-width: 80px;
      background: rgba(0, 0, 0, 0.025);
      border-top: solid 1px rgba(0, 0, 0, 0.1);
      border-radius: 3px;
      -moz-border-radius: 3px;
      -webkit-border-radius: 3px;

      i {
        margin-right: 5px;
        color: rgba(0, 0, 0, 0.5);
      }
    }
  }

  h6 {
    margin-bottom: 15px;
    font-size: 14px;
  }

  .item_author .author_list_info {
    padding-top: 10px;
    padding-left: 70px;
    color: $color;
    font-weight: bold;
  }

  .de_nav {
    text-align: left;
    margin-bottom: 30px;
  }
}

.p_list {
  margin-bottom: 30px;
}

.p_list_pp {
  position: absolute;
  display: inline-block;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-left: 0px;
  margin-top: -3px;
  z-index: 10;

  img {
    width: 100%;
    border-radius: 100% !important;
  }

  i {
    color: #ffffff;
    background: $color;
    font-size: 12px;
    padding: 3px;
    position: absolute;
    right: 0;
    bottom: 0;
    border-radius: 100%;
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
  }
}

.p_list_info {
  font-weight: 400;
  span {
    display: block;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.2em;
  }

  b {
    color: $color;
  }
}

img.img-rounded {
  border-radius: 3px;
}

/*** wallet ***/
.box-url {
  position: relative;
  text-align: center;
  padding: 20px 0;
  color: #a2a2a2;
  border: solid 1px #888;
  background: #fdfbf8;
  display: block;
  border-radius: 0;
  box-shadow: 6px 6px 0px 0px rgba(0, 0, 0, 0.09);
  transition: 0.3s;

  &:hover {
    transition: 0.3s;
  }

  &.left {
    text-align: left;
  }

  .box-url-label {
    font-weight: bold;
    position: absolute;
    right: 30px;
    color: $color;
    padding: 2px 10px;
    border: solid 1px #888;
    background: #fdfbf8;
    border-radius: 0;
  }

  i {
    font-size: 40px;
    color: $color;
    margin: 0 auto 15px;
  }

  h4 {
    font-family: "Roboto Slab", Helvetica, Arial, sans-serif;
    font-size: 14px;
    margin-bottom: 0;
  }
}

.mb30 {
  margin-bottom: 30px;
}

.mb20 {
  margin-bottom: 20px;
}

/*** login ***/
.field-set label {
  color: #606060;
  font-weight: 500;
}

.form-control {
  padding: 8px;
  margin-bottom: 10px;
  border: none;
  border: solid 1px rgba(0, 0, 0, 0.1);
  background: white;
  border-radius: 0;
  height: auto;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  color: $color;
  border-radius: 5px;
}

.list.s3 {
  display: inline;
  margin: 0;
  padding: 0;

  li {
    display: inline;
    list-style: none;
    margin-right: 20px;

    span {
      color: $color;
      font-weight: 600;
      cursor: pointer;
    }
  }
}

.spacer-10 {
  width: 100%;
  height: 10px;
  display: block;
  clear: both;
}

.align-items-center {
  align-items: center !important;
}

.box-login {
  padding: 40px;
  border: solid 1px #888;
  background: #fdfbf8;
  border-radius: 0;

  p {
    color: $white !important;

    span {
      color: $color;
      text-decoration: none;
      cursor: pointer;
    }
  }

  .btn-main {
    width: 100%;
  }

  .list.s3 {
    justify-content: left;
    font-size: 1rem;

    li {
      display: inline;
      margin-right: 20px;
      letter-spacing: normal;
      justify-content: left;
    }
  }
}

.box-wallet {
  padding: 40px;
  border: solid 1px #888;
  background: #fdfbf8;
  border-radius: 0;

  p {
    color: black !important;

    span {
      color: $color;
      text-decoration: none;
      cursor: pointer;
    }
  }

  .btn-main {
    width: 100%;
  }

  .list.s3 {
    justify-content: left;
    font-size: 1rem;

    li {
      display: inline;
      margin-right: 20px;
      letter-spacing: normal;
      justify-content: left;
    }
  }
}

/*** all icon ***/
.demo-icon-wrap-s2 {
  margin-bottom: 30px;

  span {
    display: inline-block;
    text-align: center;
    font-size: 20px;
    margin-right: 20px;
    position: relative;
    color: $color_more;
    background: rgba(131, 100, 226, 0.1);
    width: 48px;
    height: 48px;
    border-radius: 5px;
    padding: 15px 0;
  }
}

.demo-icon-wrap {
  margin-bottom: 30px;

  i {
    text-align: center;
    font-size: 20px;
    margin-right: 20px;
    position: relative;
    color: $color_more;
    background: rgba(131, 100, 226, 0.1);
    width: 48px;
    height: 48px;
    border-radius: 5px;
    padding: 15px 0;
  }

  .text-grey {
    display: none;
  }
}

/*** element ***/
.card-header {
  padding: 1rem 1.25rem;

  .btn {
    width: 100%;
    height: 100%;
    text-align: left;
    color: $general;
    padding: 0;
    margin: 0;
    border: 0;

    &:before {
      content: unset;
    }

    &:focus {
      box-shadow: unset;
    }
  }
}

.card-body {
  padding: 1rem 1.25rem;
}

.sequence {
  .mb30 {
    margin-bottom: 30px;
  }

  .pricing-s1 {
    overflow: hidden;
    position: relative;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.025);
    background-size: auto;
    box-shadow: 2px 2px 20px 0px rgba(0, 0, 0, 0.05);

    .top {
      padding: 30px;
      text-align: center;

      h2 {
        color: $color_more;
      }
    }

    .mid {
      padding: 40px;
      text-align: center;
      background: $color_more;

      .m.opt-1 {
        font-size: 48px;
        font-weight: 500;
      }
    }

    .bottom {
      border-top: 0px;
      text-align: center;

      ul {
        display: block;
        list-style: none;
        list-style-type: none;
        list-style-type: none;
        margin: 30px 0;
        padding: 0px;
        text-align: left;
        overflow: hidden;

        li {
          padding: 10px 30px;
          color: #505050;

          i {
            margin-right: 10px;
            color: $color_more;
          }
        }
      }
    }

    .action {
      text-align: center;
      padding: 40px 0;
      border-top: solid 1px rgba(255, 255, 255, 0.1);

      .btn-main {
        margin: 0 auto;
      }
    }
  }
}

.skill-bar {
  margin-bottom: 40px;

  &.style-2 {
    .de-progress {
      background: rgba(0, 0, 0, 0.1);
      background-size: auto;
      width: 100%;
      height: 8px;
      background: #ddd;
      margin-top: 20px;
      margin-bottom: 20px;
      border-radius: 8px;
      overflow: hidden;

      .progress-bar {
        background: $color_more;
        height: 8px;
        box-shadow: none;
      }
    }
  }
}

#tabs1 {
  .nav-tabs {
    font-weight: bold;
    border-bottom: 0;
    margin-bottom: 30px;
  }
}

#tabs2 {
  .nav-tabs {
    font-weight: bold;
    border-bottom: 0;
    margin-bottom: 30px;

    .nav-link {
      &:hover {
        border: 0;
      }
    }
  }

  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    color: $white;
    background: $color_more;
    border-radius: 4px;
    border: 0;
  }
}

#tabs3 {
  display: flex;
  justify-content: space-between;

  .nav {
    display: inline-block;
    margin-right: 15px;
  }

  .nav-tabs {
    font-weight: bold;
    border-bottom: 0;
    margin-bottom: 30px;

    .nav-link {
      &:hover {
        border: 0;
      }
    }
  }

  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    color: $white;
    background: $color_more;
    border-radius: 4px;
    border: 0;
  }
}

#tabs4 {
  display: flex;
  flex-direction: row-reverse;

  .nav {
    display: inline-block;
    margin-left: 15px;
  }

  .nav-tabs {
    font-weight: bold;
    border-bottom: 0;
    margin-bottom: 30px;

    .nav-link {
      &:hover {
        border: 0;
      }
    }
  }

  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    color: $white;
    background: $color_more;
    border-radius: 4px;
    border: 0;
  }
}

.card {
  background: rgba(255, 255, 255, 0.025);
}

.nav-link {
  color: $general;

  &:hover {
    color: $color_more;
  }
}

/*** filter option ***/
.item_filter_group {
  border: solid 1px rgba(0, 0, 0, 0.075);
  margin-bottom: 25px;
  padding: 30px;
  border-radius: 0;

  .de_checkbox {
    position: relative;
    display: block;
    padding-left: 25px;

    input[type="checkbox"] {
      display: none;
      appearance: none;
      border: none;
      outline: none;
    }
  }

  .de_form input[type="checkbox"]:checked + label {
    border: none;
  }

  .de_form input[type="checkbox"] + label::before {
    position: absolute;
    content: "";
    display: inline-block;
    font-family: "FontAwesome";
    margin-right: 10px;
    border: solid 2px rgba(0, 0, 0, 0.2);
    width: 16px;
    height: 16px;
    margin-top: 5px;
    left: 0;
    font-size: 11px;
    padding: 1px 3px 0 3px;
    line-height: 15px;
    border-radius: 4px;
  }

  .de_form input[type="checkbox"]:checked + label::before {
    content: "\f00c";
    color: #ffffff;
    border: rgba(0, 0, 0, 0);
    background: $color;
  }
}

/*** table ***/
.de-table.table-rank {
  color: $color;
  display: table;
  margin-bottom: 0;
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #212529;
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: #212529;
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: #212529;
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  vertical-align: top;
  border-color: #dee2e6;

  thead {
    border-style: hidden !important;
    padding-bottom: 15px;
  }

  tr {
    border-bottom: solid 1px rgba(0, 0, 0, 0.1) !important;

    &.spacer-single {
      border-bottom: 0 !important;
    }
  }

  th {
    padding: 30px 0;
  }

  td {
    padding: 30px 0;
  }

  .coll_list_pp {
    position: absolute;
    display: inline-block;
    width: 50px;
    height: auto;
    margin-left: 10px;
    margin-top: -12px;
    z-index: 10;
    left: 0;

    img {
      width: 100%;
      border-radius: 100% !important;
      position: relative;
      z-index: 1;
    }

    i {
      color: $white;
      background: $color;
      font-size: 12px;
      padding: 3px;
      position: absolute;
      right: 0;
      bottom: 0;
      border-radius: 100%;
      z-index: 2;
    }
  }

  tbody th {
    position: relative;
    padding-left: 75px;
  }

  .d-plus {
    color: #34c77b;
  }

  .d-min {
    color: #eb5757;
  }
}

.pagination {
  margin: 0 auto;
  font-weight: 500;
  border-radius: 3px;

  li {
    span {
      border: solid 1px rgba(255, 255, 255, 0.1);
      padding: 15px 20px 15px 20px;
      color: $color;
      cursor: pointer;
    }
  }

  li.active {
    span {
      color: $white;
      border-color: $color;
      border-right: none;
      background: $color;
    }
  }
}

#form_sb {
  .col {
    display: flex;
  }

  input[type="text"] {
    padding: 21px 12px 21px 12px;
    width: 85%;
    height: 30px;
    float: left;
    margin-bottom: 0;
    border-radius: 8px 0 0 8px;
    border: none;
    background: rgba(255, 255, 255, 0.7);
  }

  #btn-submit {
    border: none;
    background: none;
    margin: 0;
    padding: 0;

    i {
      text-align: center;
      font-size: 30px;
      float: left;
      width: 100%;
      color: #ffffff;
      background: $color;
      display: table-cell;
      padding: 5px 40px 7px 40px;
      border-radius: 0 8px 8px 0;
    }
  }
}

.text-danger {
  margin-left: 2px;
}

.has_offers {
  .through {
    text-decoration: line-through;
  }
}

.icontype {
  position: absolute;
  right: 10px;
  top: 5px;
  font-size: 0.8rem;
  opacity: 0.2;
}

/* checkout */
.checkout {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 999;

  .maincheckout {
    position: relative;
    border-radius: 16px;
    background: $white;
    width: 96%;
    max-width: 700px;
    height: max-content;
    margin: 30px 0 0;
    padding: 24px;

    .top {
      padding: 22px 28px;
      border-bottom: 1px solid lightgray;
    }

    .bottom {
      padding: 22px 28px;

      .progress {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        overflow: hidden;
        border: 2px solid lightgray;
        font-weight: 700;
        font-size: 14px;
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      button {
        background-color: rgb(231, 231, 231);
        border-radius: 12px;
        padding: 14px 20px;
        font-size: 17px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.456);
        margin-top: 5px;
      }
    }

    .heading {
      display: flex;
      justify-content: space-between;
      align-items: center;

      p {
        margin-bottom: 0;
        font-size: 20px;
        font-weight: 500;
        letter-spacing: normal;
      }

      span {
        font-size: 20px;
        color: lightgray;
        cursor: pointer;
      }

      .subtotal {
        color: $black;
        font-size: 15px;
      }
    }

    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 35px;

      > div:first-child {
        display: flex;
        align-items: center;

        img {
          width: 46px;
          height: 46px;
          border-radius: 10px;
          margin-right: 16px;
        }
      }

      > div:last-child {
        text-align: right;
      }

      p {
        font-size: 14px;
        font-weight: 500;
      }
    }

    .detailcheckout {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .listcheckout {
        width: 100%;
        display: flex;
        align-items: left;
        flex-direction: column;

        .thumb {
          width: 90px;
        }

        .description {
          padding: 15px;

          h3,
          p {
            margin-bottom: 0;
          }
        }

        input {
          margin: 10px 0 15px;
        }
      }

      .price {
        font-weight: 600;
      }
    }

    .agrement {
      display: flex;
      align-items: center;

      label {
        padding-left: 5px;
      }
    }

    .btn-main {
      width: 100%;
      margin: 30px auto;
      margin-bottom: 15px !important;
    }

    .bold {
      color: $black;
      font-weight: bold;
    }
  }
}

.nft_attr {
  display: block;
  padding: 10px;
  text-align: center;
  background: rgba(0, 0, 0, 0.15);
  border: solid 1px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  margin-bottom: 8px;
  text-decoration: none;
  cursor: pointer;

  h5 {
    color: $color;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 5px;
  }

  h4 {
    font-size: 15px;
    font-weight: 700;
    color: #0d0c22;
    margin-bottom: 0;
  }

  span {
    font-size: 13px;
    color: #727272;
  }
}

/* blog */
/* ================================================== */
.blog-list {
  margin: 0;
  padding: 0;
}

blockquote {
  font-family: var(--body-font);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.4em;
  border: none;
  border-left-color: currentcolor;
  border-left-style: none;
  border-left-width: medium;
  border-left: solid 3px #333333;
  border-left-color: rgb(51, 51, 51);
  padding-left: 30px;
  color: #606060;
}

/* blog list */
.blog-list > div {
  list-style: none;
  line-height: 1.7em;
  margin-top: 0px;
  border-bottom: solid 1px rgba(255, 255, 255, 0.1);
  margin-bottom: 20px;
}

.blog-list img {
  margin-bottom: 20px;
}

.de_light .blog-list li {
  border-bottom: solid 1px #ddd;
}

.blog-list h4 {
  margin-top: 20px;
}

.blog-list h4 a {
  color: #fff;
}

.de_light .blog-list h4 a {
  color: #222222;
}

.de_light .text-light .blog-list h4 a {
  color: #fff;
}

.blog-list .btn-more {
  padding: 3px 25px 3px 25px;
  color: #111;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 700;
  float: right;
}

.blog-list .btn-more:hover {
  background: #fff;
}

.de_light .blog-list .btn-more:hover {
  background: #222;
  color: #fff;
}

.blog-list .post-text {
  padding-left: 80px;
}

.bloglist .post-text a.btn-main {
  display: inline-block;
  margin-top: 0px;
  padding: 5px 25px;
}

.blog-list .blog-slider {
  margin-bottom: -60px;
}

.blog-list .date-box {
  position: absolute;
  text-align: center;
  text-shadow: none;
}

.blog-list .date-box .day,
.blog-list .date-box .month {
  display: block;
  color: #fff;
  text-align: center;
  width: 40px;
  z-index: 100;
}

.de_light .blog-list .date-box .month {
  color: #222222;
}

.blog-list .date-box {
  text-align: center;
  background: #f6f6f6;
  padding: 10px;
}

.blog-list .date-box .month {
  font-size: 14px;
}

.blog-list .date-box .day {
  font-family: var(--body-font);
  color: #222222;
  font-weight: bold;
  font-size: 28px;
}

.blog-list .date-box .month {
  font-family: var(--body-font);
  color: #fff;
  font-size: 13px;
  letter-spacing: 3px;
}

.de_light .blog-list .date-box .month {
  border-bottom-color: #ddd;
}

.de_light .text-light .blog-list .date-box .month {
  color: #fff;
}

.blog-snippet li {
  border-bottom: none;
  margin-bottom: 0;
}

#blog-carousel .item.item {
  width: 100%;
}

/* blog comment */
#blog-comment {
  margin-top: 40px;
}

#blog-comment h5 {
  margin-bottom: 10px;
}

#blog-comment ul,
#blog-comment li {
  list-style: none;
  margin-left: -40px;
  padding-left: 0;
}

#blog-comment li {
  min-height: 70px;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: solid 1px #eee;
}

.dark-scheme #blog-comment li {
  border-bottom-color: rgba(255, 255, 255, 0.1);
}

#blog-comment li .avatar {
  position: absolute;
  margin-top: 5px;
}

#blog-comment .comment {
  margin-left: 85px;
}

#blog-comment li {
  min-height: 70px;
  font-size: 14px;
  line-height: 1.6em;
}

#blog-comment li li {
  margin-left: 55px;
  padding-bottom: 0px;
  margin-top: 20px;
  padding-top: 10px;
  border-top: solid 1px #eee;
  border-bottom: none;
}

.dark-scheme #blog-comment li li {
  border-top-color: rgba(255, 255, 255, 0.1);
}

#blog-comment li li .avatar {
  position: absolute;
}

#blog-comment li li .comment {
}

.comment-info {
  margin-left: 85px;
  margin-bottom: 5px;
}

.comment-info span {
}

.comment-info .c_name {
  display: block;
  font-weight: 700;
  color: #555555;
}

.dark-scheme .comment-info .c_name {
  color: #ffffff;
}

.comment-info .c_reply {
  padding-left: 20px;
  margin-left: 20px;
  border-left: solid 1px #ddd;
  font-size: 14px;
}

.dark-scheme .comment-info .c_reply {
  border-left-color: rgba(255, 255, 255, 0.2);
}

.comment-info .c_date {
  font-size: 14px;
}

.blog-list h4 {
  color: #333;
}

.blog-list .post-wrapper {
}

.blog-read h4 {
  color: #333;
  letter-spacing: normal;
  font-size: 24px;
  text-transform: none;
}

.blog-carousel ul,
.blog-carousel li {
  margin: 0;
  list-style: none;
}

.blog-carousel h4 {
  margin-bottom: 0px;
}

.blog-carousel p {
  margin-bottom: 10px;
}

.blog-carousel .post-date {
  text-transform: uppercase;
  letter-spacing: 3px;
  font-size: 11px;
  color: #888;
}

.post-content {
  position: relative;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  overflow: hidden;
  margin-bottom: 20px;
}

.post-content p {
  margin-bottom: 20px;
}

.post-text {
  padding-top: 20px;
}

.post-text h4 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.post-text h4 a {
  color: #1a1c26;
  text-decoration: none;
  font-size: 22px;
  font-weight: bold;
}

.dark-scheme .post-text h4 a {
  color: #ffffff;
}

.post-text .tags {
  font-size: 12px;
  text-transform: uppercase;
}

.p-tagline {
  position: absolute;
  z-index: 1;
  top: 20px;
  left: 20px;
}

.p-tagline,
.p-title {
  font-family: var(--body-font);
  background: rgba(var(--primary-color-rgb), 0.2);
  border-radius: 3px;
  display: inline-block;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 2px;
  line-height: 10px;
  padding: 7px 10px 6px 10px;
  text-transform: uppercase;
  margin-bottom: 5px;
}

.p-tagline,
.p-title.invert {
  background: var(--secondary-color);
}

.p-title {
  font-size: 14px;
  margin-bottom: 20px;
}

.post-text .p-date {
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 12px;
  font-weight: 500;
}

.post-info {
  border-top: solid 1px #eeeeee;
  padding-top: 15px;
}

.blog-read img {
  margin-bottom: 20px;
}

.blog-read .img-fullwidth {
  width: 100%;
  height: auto;
}

.blog-read .post-text {
  padding: 0;
}

.post-date,
.post-comment,
.post-like,
.post-by,
.post-author {
  margin-right: 20px;
  font-size: 13px;
  color: #999;
  font-family: var(--body-font);
}

.dark-scheme .post-date,
.dark-scheme .post-comment,
.dark-scheme .post-like,
.dark-scheme .post-by,
.dark-scheme .post-author {
  color: #ffffff;
}

.post-author {
  color: #777;
}

.post-date:before,
.post-comment:before,
.post-like:before,
.post-by:before {
  font-family: FontAwesome;
  padding-right: 10px;
  font-size: 11px;
}

.post-comment:before {
  content: "\f0e5";
}

.post-like:before {
  content: "\f08a";
}

.post-author:before {
  content: "By: ";
}

/* blog comment form */
#comment-form-wrapper {
  .error,
  .success {
    display: none;
  }
}

#commentform label {
  display: block;
}

#commentform input {
  width: 290px;
}

#commentform input:focus {
  border: solid 1px #999;
  background: #fff;
}

#commentform textarea {
  width: 97%;
  padding: 5px;
  height: 150px;
  color: #333;
}

#commentform textarea:focus {
  border: solid 1px #999;
  background: #fff;
}

#commentform input.btn {
  width: auto;
}

.post-meta {
  margin: 0px 0 10px 0px;
  border: solid 1px #eee;
  border-left: none;
  border-right: none;
  border-left: none;
  font-size: 11px;
  display: table;
  width: 100%;
  table-layout: fixed;
}

.post-meta a {
  color: #888;
}

.post-meta span {
  display: table-cell;
  padding: 10px 0 10px 0;
  text-align: center;
}

.post-meta span i {
  float: none;
}

.post-image img {
  width: 100%;
  margin-bottom: 0px;
}

.blog-slide {
  padding: 0;
  margin: 0;
}

.blog-item {
  margin-bottom: 60px;
}

.blog-item img.preview {
  width: 100%;
  margin-bottom: 30px;
}

.post-image {
  position: relative;
}

.post-image .post-info {
  position: absolute;
  bottom: 0;
  padding: 0;
  border: none;
  width: 100%;
}

.post-image .post-info .inner {
  margin: 0 30px 0 30px;
  padding: 3px 20px;
  border-radius: 10px 10px 0 0;
}

.post-image .post-date,
.post-image .post-author {
  color: #ffffff;
}

.single-coll-textWrap {
  display: flex;
  align-items: center;

  > span {
    margin: 0 32px;
    color: white;
    font-size: 20px;
  }
}

.sing-coll-title {
  font-size: 80px !important;
}

.widget {
  margin-bottom: 30px;
  padding-bottom: 30px;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .small-border {
    height: 2px;
    width: 30px;
    margin: 0px 0 20px 0;
    display: block;
  }

  h4 {
    font-size: 18px;
    margin-top: 0;
    letter-spacing: normal;
  }
}

.widget-post {
  li {
    border-bottom: solid 1px #f2f2f2;
    padding-bottom: 8px;
    margin-bottom: 8px;

    a {
      padding-left: 60px;
      display: block;
      text-decoration: none;
      color: #606060;
    }
  }

  .date {
    background: $color_more;
    color: #fff;
    display: inline-block;
    padding: 5px;
    float: left;
    font-size: 14px;
  }
}

.widget_tags {
  li {
    text-shadow: none;
    display: inline-block;
    margin: 5px 6px;

    a {
      font-size: 14px;
      text-decoration: none;
      margin-bottom: 5px;
      border-radius: 5px;
      -moz-border-radius: 5px;
      -webkit-border-radius: 5px;
      color: #fff !important;
      background: $color_more;
      padding: 6px 12px 6px 12px;
    }
  }
}

/*** create 2 ***/
.de_tab.tab_methods {
  .de_nav {
    text-align: left;

    li {
      margin: 0 10px 0 0;

      span {
        padding: 35px 10px;
        color: rgba(0, 0, 0, 0.6);
        background: none;
        border: solid 2px rgba(0, 0, 0, 0.1);
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        display: table-cell;
        vertical-align: middle;
        border-radius: 12px;
        min-width: 140px;

        i {
          display: block;
          font-size: 30px;
          margin-bottom: 10px;
        }
      }

      &.active {
        span {
          border-color: $color;

          i {
            color: $color;
          }
        }
      }
    }
  }
}

.switch-with-title {
  h5 {
    float: left;

    i {
      color: $color;
      margin-right: 10px;
    }
  }

  .p-info {
    font-size: 14px;
    margin: 0;
    padding: 0 0 10px 0;
  }

  .de-switch {
    float: right;
    display: block;

    label {
      transition: all 200ms ease-in-out;
      display: inline-block;
      position: relative;
      height: 20px;
      width: 40px;
      border-radius: 40px;
      cursor: pointer;
      background-color: rgba(0, 0, 0, 0.3);
      color: transparent;

      &::after {
        transition: all 200ms ease-in-out;
        content: " ";
        position: absolute;
        height: 14px;
        width: 14px;
        border-radius: 50%;
        background-color: white;
        top: 3px;
        left: 3px;
        right: auto;
      }
    }

    input[type="checkbox"] {
      display: none;
    }

    input[type="checkbox"]:checked + label::after {
      left: 23px;
    }

    input[type="checkbox"]:checked + label {
      background-color: $color;
    }
  }
}

.hide {
  display: none !important;
}

.show {
  display: block !important;
}

#form_sb {
  .col {
    display: flex;
  }

  input[type="text"] {
    padding: 21px 12px 21px 12px;
    width: 85%;
    height: 30px;
    float: left;
    margin-bottom: 0;
    border-radius: 8px 0 0 8px;
    border: none;
    background: rgba(255, 255, 255, 0.7);
  }

  #btn-submit {
    border: none;
    background: none;
    margin: 0;
    padding: 0;

    i {
      text-align: center;
      font-size: 30px;
      float: left;
      width: 100%;
      color: #ffffff;
      background: $color;
      display: table-cell;
      padding: 5px 40px 7px 40px;
      border-radius: 0 8px 8px 0;
    }
  }
}

.opt-create {
  display: inline-block;
  text-align: center;
  border: solid 2px rgba(0, 0, 0, 0.1);
  background: rgba(0, 0, 0, 0.03);
  padding: 40px;
  min-width: 44%;
  margin: 2%;
  border-radius: 15px;
  transition: 0.3s;
  text-decoration: none;

  img {
    position: relative;
    width: 100px;
  }

  h3 {
    font-size: 18px;
    margin-top: 25px;
    margin-bottom: 0;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.1);
    border-color: $color;
    box-shadow: 2px 2px 30px 0px rgba(20, 20, 20, 0.1);
    transition: 0.3s;
  }
}

//logout menu
.mainside {
  width: max-content;
  padding-left: 0;
  padding-right: 0;

  a {
    text-align: center;
    color: #fff !important;
    background: #8364e2;
    border-radius: 6px;
    letter-spacing: normal;
    outline: 0;
    font-weight: 800;
    text-decoration: none;
    padding: 6px 20px;
    font-size: 14px;
    border: none;
    cursor: pointer;
    box-shadow: 2px 2px 20px 0px rgba(131, 100, 226, 0);
    transition: all 0.3s ease;

    &:hover {
      box-shadow: 2px 2px 20px 0px rgba(131, 100, 226, 0.5);
      transition: all 0.3s ease;
    }
  }

  .connect-wal {
    display: block;
  }

  .logout {
    display: none;

    a {
      display: inline-block;
      margin: 0 5px;
    }
  }

  .de-menu-notification {
    margin: 0 5px;
    position: relative;
    cursor: pointer;
    display: inline-block;
    width: 38px;
    height: 38px;
    background: #eeeeee;
    text-align: center;
    line-height: 32px;
    border-radius: 30px;
    padding-top: 3px;
    margin-left: 5px;

    .d-count {
      display: inline-block;
      position: absolute;
      z-index: 100;
      background: $color;
      line-height: 1em;
      padding: 6px;
      width: 22px;
      border-radius: 30px;
      top: -10px;
      right: -10px;
      color: #ffffff;
      font-weight: bold;
      font-size: 12px;
    }

    .popshow {
      cursor: default;
      width: 250px;
      position: absolute;
      background: $white;
      border-radius: 5px;
      overflow: hidden;
      inset: 50px auto auto -200px;
      padding: 20px;
      animation: smoothDrop 0.2s ease;
      -webkit-animation: smoothDrop 0.2s ease;
      box-shadow: 2px 2px 30px 0px rgba(20, 20, 20, 0.1);
      z-index: 1;

      h4 {
        font-weight: bold;
        display: block;
        margin: 0;
        margin-top: 0px;
        padding: 0;
        font-size: 16px;
        margin-top: 5px;
      }

      span.viewaall {
        color: $color;
      }

      ul {
        margin: 0;
        margin-top: 0px;
        margin-top: 10px;
        padding: 0;
        list-style: none;
        text-align: left;

        li {
          line-height: 20px;

          .mainnot {
            color: #0d0c22;

            img {
              width: 32px;
              height: 32px;
              position: absolute;
              border-radius: 30px;
            }

            .d-desc {
              position: relative;
              top: -20px;
              margin-left: 45px;

              b {
                font-weight: bold;
              }

              .d-time {
                display: block;
                font-size: 14px;
                color: #727272;
              }
            }
          }
        }
      }
    }
  }

  .de-menu-profile {
    position: relative;
    cursor: pointer;

    img {
      width: 38px;
      border-radius: 30px;
      -moz-border-radius: 30px;
      -webkit-border-radius: 30px;
      margin-left: 5px;
      margin-bottom: 2px;
    }

    .popshow {
      cursor: default;
      width: 250px;
      position: absolute;
      background: $white;
      border-radius: 5px;
      overflow: hidden;
      inset: 50px auto auto -200px;
      padding: 20px;
      animation: smoothDrop 0.2s ease;
      -webkit-animation: smoothDrop 0.2s ease;
      box-shadow: 2px 2px 30px 0px rgba(20, 20, 20, 0.1);
      z-index: 1;

      .d-name {
        margin-bottom: 15px;

        h4 {
          font-weight: bold;
          display: block;
          margin: 0;
          margin-top: 0px;
          padding: 0;
          font-size: 16px;
          margin-top: 5px;
        }

        span.name {
          color: $color;
          cursor: pointer;
        }
      }

      .d-balance {
        font-size: 14px;
        margin-bottom: 15px;

        h4 {
          font-weight: bold;
          display: block;
          margin: 0;
          margin-top: 0px;
          padding: 0;
          font-size: 16px;
          margin-top: 5px;
        }
      }

      .d-wallet {
        font-size: 14px;
        margin-bottom: 15px;

        h4 {
          font-weight: bold;
          display: block;
          margin: 0;
          margin-top: 0px;
          padding: 0;
          font-size: 16px;
          margin-top: 5px;
        }

        .d-wallet-address {
          display: inline-block;
          max-width: 64%;
          overflow: hidden;
        }

        #btn_copy {
          margin-left: 15px;
        }
      }

      .d-line {
        margin: 15px 0 15px 0;
        border-top: solid 1px #bbbbbb;
      }

      .de-submenu-profile {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
          padding: 5px 0;

          span {
            cursor: pointer;
            color: #0d0c22 !important;
            background: none;
            padding: 0;
            font-weight: bold;
            font-size: 14px;
            display: block;
            text-align: left;

            &:hover {
              box-shadow: none;
            }

            i {
              padding: 7px 9px;
              background: #eee;
              border-radius: 30px;
              width: 28px;
              margin-right: 5px;
            }
          }
        }
      }
    }
  }

  .de-menu-profile1 {
    position: relative;
    cursor: pointer;
    margin-left: 6px;

    img {
      width: 38px;
      margin-left: 5px;
      margin-bottom: 2px;
    }

    .popshow {
      cursor: default;
      width: 250px;
      position: absolute;
      background: $white;
      border-radius: 5px;
      overflow: hidden;
      inset: 50px auto auto -200px;
      padding: 20px;
      animation: smoothDrop 0.2s ease;
      -webkit-animation: smoothDrop 0.2s ease;
      box-shadow: 2px 2px 30px 0px rgba(20, 20, 20, 0.1);
      z-index: 1;

      .d-name {
        margin-bottom: 15px;

        h4 {
          font-weight: bold;
          display: block;
          margin: 0;
          margin-top: 0px;
          padding: 0;
          font-size: 16px;
          margin-top: 5px;
        }

        span.name {
          color: $color;
          cursor: pointer;
        }
      }

      .d-balance {
        font-size: 14px;
        margin-bottom: 15px;

        h4 {
          font-weight: bold;
          display: block;
          margin: 0;
          margin-top: 0px;
          padding: 0;
          font-size: 16px;
          margin-top: 5px;
        }
      }

      .d-wallet {
        font-size: 14px;
        margin-bottom: 15px;

        h4 {
          font-weight: bold;
          display: block;
          margin: 0;
          margin-top: 0px;
          padding: 0;
          font-size: 16px;
          margin-top: 5px;
        }

        .d-wallet-address {
          display: inline-block;
          max-width: 64%;
          overflow: hidden;
        }

        #btn_copy {
          margin-left: 15px;
        }
      }

      .d-line {
        margin: 15px 0 15px 0;
        border-top: solid 1px #bbbbbb;
      }

      .de-submenu-profile1 {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
          padding: 5px 0;

          span {
            cursor: pointer;
            color: #0d0c22 !important;
            background: none;
            padding: 0;
            font-weight: bold;
            font-size: 14px;
            display: block;
            text-align: left;

            &:hover {
              box-shadow: none;
            }

            i {
              padding: 7px 9px;
              background: #eee;
              border-radius: 30px;
              width: 28px;
              margin-right: 5px;
            }
          }
        }
      }
    }
  }
}

/*** media all ***/
@media only screen and (max-width: 1199px) {
  .item-dropdown {
    position: relative;
    width: 100%;
    inset: unset;
    padding-top: 10px;
    border: 0;
    background: none;
    box-shadow: unset;

    .dropdown {
      a {
        color: $color !important;
        padding: 10px 10px 10px 0px;
        width: 100%;
        text-align: left;
        left: 10px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      }
    }
  }

  .navbar {
    top: 0;

    &.white a {
      color: $color;
    }
  }

  #myHeader .container {
    max-width: unset !important;
  }

  .mainside a span {
    display: none;
  }

  .mainside a i {
    color: $white;
    display: inline-block;
  }

  .navbar .logo .navbar-item a {
    position: relative;
    top: 15px;
  }
}

.activity-table {
  width: 100%;
  overflow: hidden;

  .activity-table-wrapper {
    width: 100%;
    overflow: scroll;
  }
  td,
  th {
    text-transform: capitalize;
  }

  th {
    background-color: #d5d5d5;
    text-align: center;
    color: black;
  }

  td {
    text-align: left;
    color: #3c3c3b;
  }

  .activity-from,
  .activity-to {
    color: #8364e2 !important;
  }
}

.event-td-img {
  img {
    width: 15px;
    margin-right: 10px;
  }
}

.item-td-img {
  img {
    width: 28px;
    height: 28px;
    margin-right: 10px;
  }
}

@media only screen and (max-width: 1199px) {
  .m-10-hor {
    padding-left: 2%;
    padding-right: 2%;
  }

  .subfooter .de-flex,
  .subfooter .de-flex-col {
    display: block;
  }

  footer.footer-light .subfooter span.copy {
    display: block;
    margin: 20px 0;
  }
}

@media only screen and (max-width: 991px) {
  .zen_home {
    .flex-column {
      margin-bottom: 40px;
    }

    .top-text {
      margin-left: 0;
    }

    .side-feature {
      padding-left: 0;
    }
  }

  .ml-custom {
    padding-left: 0.7rem;
  }

  #sidebar {
    margin-bottom: 15px;
  }
}

.bulk-collection {
  border-top: 1px solid #a3a2a2;

  .bulk-steps {
    margin-top: 14px;

    > div {
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      // cursor: pointer;
    }

    .step-number {
      width: 27px;
      height: 27px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 15px;
      background-color: white;
      border: 1px solid #8364e2;
      color: #8364e2;

      &.current {
        color: white;
        background-color: #8364e2;
      }

      &.completed {
        color: white;
        background-color: #549205;
        border: none;
      }
    }

    p {
      color: #b9b9b9;
      font-size: 15px;
      margin: 0 6px;

      &.current {
        color: #3c3c3b;
      }
    }
  }

  .bulk-right {
    .b-bottom {
      border-bottom: 1px solid #a3a2a2;
    }

    input {
      border: 1px solid #a3a2a2;
    }

    input[type="text"],
    select {
      height: 35px;
    }

    select {
      // max-width: 200px;
    }

    .drag-file {
      width: 100%;
      min-height: 300px;
      border: 2px dashed #c4c4c4;
      background-color: white;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top;
      }

      .maxi-icon {
        position: absolute;
        right: 7px;
        top: 7px;
        z-index: 9;
        background-color: #69696999;
        width: 21px;
        height: 21px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }

    .freeze-metadata {
      .choose-file {
        font-size: 9px;
        padding: 4px 26px;
      }
    }

    .list-sale {
      .title-border {
        background-color: #f0f0f0;
        width: 120px;
      }

      .offer-amount input {
        max-width: unset;
        margin-left: 8px;
        padding-left: 10px;
      }

      .bulk-input {
        position: relative;

        input {
          padding-left: 10px;
        }
      }

      .custom-fee {
        border-bottom: none;
      }
    }
  }
}

@media screen and (max-width: 820px) {
  .xs-hide {
    display: none !important;
  }
}
@media screen and (min-width: 820px) {
  .xs-only {
    display: none !important;
  }
}


@media only screen and (max-width: 1199px) {
  #form-create-item.c-nft-wrapper {
    h5 {
      display: flex;
      flex-direction: column;
      margin-bottom: 6px !important;

      span {
        margin-left: 0 !important;
      }
    }
  }

  .sing-coll-title {
    font-size: 40px !important;
  }

  #myHeader {
    position: sticky;
    top: 0;
  }
  .detail_img {
    min-height: unset !important;
    max-height: 550px;
  }

  .creator-sellers {
    flex-wrap: wrap !important;
    justify-content: center;

    .cs {
      width: 50% !important;
      margin-bottom: 10px !important;
    }
  }

  .connect-wallet-footer {
    flex-direction: column;
  }

  .profile-item-details {
    flex-direction: column;
    align-items: flex-start !important;
  }

  .my_profile .my_profile_name .addr_copy {
    margin: 8px 0 !important;
  }

  .my_profile .my_profile_name .profile_joined {
    margin-left: 0 !important;
  }

  .my_profile .my_profile_name h4 {
    font-size: 30px !important;
    margin-bottom: 10px !important;
  }

  .my_profile .my_profile_avatar {
    min-width: 90px;
    width: 90px !important;
    height: 90px !important;
  }

  .mt-profile {
    margin-top: -45px !important;
  }

  .feature-box.f-boxed {
    max-width: 360px;
    margin: 0 auto;
  }

  .d-item,
  .collection {
    max-width: 450px;
    margin: 0 auto;
  }

  .text-center-sm {
    text-align: center;
  }

  .breadcumb .mainbreadcumb .list {
    justify-content: left;
    text-align: left;
    margin-bottom: 30px;
  }

  .previousButton,
  .nextButton {
    opacity: 1 !important;
  }

  .container {
    padding-left: 30px;
    padding-right: 30px;
  }

  .author_list {
    column-count: 1;
    column-gap: 20px;
  }

  #form_quick_search {
    top: 0;
    width: 100%;

    .col {
      padding-right: 0;
    }
  }

  #form_quick_search input[type="text"] {
    width: 80%;
  }

  #form_quick_search #btn-submit {
    width: 20%;
  }

  #form_quick_search #btn-submit i {
    width: 100%;
  }

  .items_filter .dropdownSelect {
    margin-right: 0;
    width: 96%;
  }

  .item_info {
    margin-top: 30px;
  }

  .profile_avatar {
    display: block;
  }

  .d_profile {
    display: block;

    .de-flex-col {
      display: block;
    }
  }

  .profile_name {
    margin-left: 0;
    margin-top: 20px;
    display: block;
    width: 100%;
  }

  .profile_follow {
    margin-top: 50px;
    width: 100%;
  }

  .nft__item {
    margin: 0 0 30px 0;
  }

  .de_nav.text-left {
    margin-left: 0;
  }
}

// normal styles

.top-text {
  margin-left: 40px;
}

.curr_price {
  font-size: 14px;
  color: #696969;
  line-height: 14px;
  margin-bottom: 0;
}

.price-eth {
  font-size: 16px;
  font-weight: 600 !important;
  color: black !important;
  margin-left: 0 !important;
  line-height: 1;
}

.price-usd {
  color: #696969 !important;
  font-size: 12px !important;
  line-height: 1;
}

// general styles
.f-10 {
  font-size: 10px !important;
}

.f-11 {
  font-size: 11px !important;
}

.f-12 {
  font-size: 12px !important;
}

.f-13 {
  font-size: 13px !important;
}

.f-14 {
  font-size: 14px !important;
}

.f-15 {
  font-size: 15px !important;
}

.f-16 {
  font-size: 16px !important;
}

.f-18 {
  font-size: 18px !important;
}

.f-20 {
  font-size: 20px !important;
}

.f-22 {
  font-size: 22px !important;
}

.f-28 {
  font-size: 28px !important;
}

.f-30 {
  font-size: 30px !important;
}

.f-35 {
  font-size: 35px !important;
}

.f-40 {
  font-size: 40px !important;
}

.f-300 {
  font-weight: 300 !important;
}

.f-400 {
  font-weight: 400 !important;
}

.f-500 {
  font-weight: 500 !important;
}

.f-600 {
  font-weight: 600 !important;
}

.f-700 {
  font-weight: 700 !important;
}

.w-30 {
  width: 30px !important;
}

.w-50 {
  width: 50px !important;
}

.custom-arrow {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(225, 225, 225);

  span {
    font-size: 13px;
    color: rgb(172, 171, 171);
  }
}

.custom-arrow.slick-prev:before,
.custom-arrow.slick-next:before {
  display: none !important;
}

// end of general

.author-list-name,
.coll-list-name {
  h6 {
    color: #696969 !important;
    line-height: 12px !important;
    font-weight: 500 !important;
    font-size: 12px !important;
    margin: 0 !important;
  }

  p {
    color: #8364e2 !important;
    margin-top: 1px !important;
    line-height: 12px !important;
    font-weight: 500 !important;
    font-size: 12px !important;
    margin: 0 !important;
  }
}

.lower-info-cont {
  border-top: 1px solid lightgrey;
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
  margin-top: auto;
}

.collections {
  display: flex;

  .collection {
    position: relative;

    &:nth-of-type(odd) {
      // margin-right: 3%;
    }

    &:nth-of-type(even) {
      // margin-left: 3%;
    }

    .coll-img-wrap {
      height: 260px;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    .coll-content {
      padding: 14px;
      padding-bottom: 18px;

      h4 {
        margin-bottom: 16px;
        font-weight: 400;
        font-size: 40px;

        span {
          margin-left: 14px;
          color: lightgray;
        }
      }

      .coll-more-info {
        display: flex;
        justify-content: space-evenly;

        p {
          margin-top: 0 !important;
        }

        .coll-profile {
          display: flex;
          align-items: center;

          .coll-inner-img {
            height: 40px;
            width: 40px;
            overflow: hidden;
            border-radius: 50%;
            margin-right: 8px;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }

        .coll_nft_owner,
        .coll_tags_vol {
          p {
            font-size: 14px;
            line-height: 20px;
            color: #696969;
            margin-bottom: 1px;

            span:first-child {
              font-weight: 600;
            }
          }
        }
      }
    }
  }
}

.creator-sellers {
  display: flex;
  margin-top: 20px;
  margin-left: -2%;

  .cs {
    border: solid 1px lightgray;
    max-width: 180px;
    width: 21%;
    margin: 0 2%;
    text-align: center;
    padding: 10px 20px;
    background-color: white;
    border-radius: 5px;

    .cs-img-wrap {
      overflow: hidden;
      border-radius: 50%;
      margin: 0 auto;

      img {
        border-radius: 100% !important;
        -moz-border-radius: 100% !important;
        -webkit-border-radius: 100% !important;
        position: relative;
        width: 80px;
        height: 80px;
        object-fit: cover;
      }
    }

    .cs-more-info {
      margin-top: 10px;
      line-height: 22px;

      p {
        color: #696969;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 2px;
      }

      .cs-name {
        color: #8364e2;
        margin-bottom: 3px;
      }

      .cs-nfts {
        font-size: 18px;
        margin-bottom: 0;
      }
    }
  }
}

.profile-img-edit {
  .modal-header {
    background-color: rgb(87, 49, 49);
    padding: 0 !important;

    .btn-close {
      margin-top: 6px !important;
      margin-right: 6px !important;
      filter: invert(1);
    }
  }
  .modal-body {
    padding: 0 !important;
    background-color: rgb(87, 49, 49);
    padding: 12px 0 20px 0 !important;
    position: relative;

    .edit-text {
      position: absolute;
      bottom: 4px;
      left: 4px;
      span {
        cursor: pointer;
      }

      label input {
        visibility: hidden;
        cursor: pointer;
      }
    }
  }
  .img-edit-wrap {
    max-width: 440px;
    margin: 0 auto;
    height: 440px;
    border-radius: 50%;
    overflow: hidden;

    img {
      width: 100%;
      object-fit: cover;
    }
  }
}

.my_profile {
  display: flex;

  .my_profile_avatar {
    width: 150px;
    height: 150px;
    overflow: hidden;
    border-radius: 50%;
    border: 4px solid white;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .my_profile_name {
    margin-left: 26px;
    flex: 1;

    &.sing-coll {
      margin-left: 50px;
    }

    h4 {
      color: white;
      font-size: 40px;
      margin-bottom: 30px;

      span {
        font-size: 25px;
        margin-left: 14px;
        color: lightgray;
      }
    }

    .my_profile_username {
      text-transform: capitalize;
      color: #8364e2;
      font-size: 22px;
      font-weight: 700;
      margin-right: 40px;
    }

    .my_profile_wallet {
      font-weight: 500;
      color: #3c3c3b;
      font-size: 14px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .addr_copy {
      font-size: 12px;
      padding: 2px 8px;
      line-height: 1em;
      border: solid 1px #8364e2;
      display: inline-block;
      border-radius: 5px;
      outline: none;
      margin-left: 10px;
    }

    .profile_joined {
      color: #a3a2a2;
      font-weight: 500;
      font-size: 14px;
      margin-left: 10%;
    }

    .profile_desc p {
      font-weight: 500;
      line-height: 17.5px;
      font-size: 14px;
    }
  }
}

.mt-profile {
  margin-top: -65px;
  z-index: 9;
}

.profile_nav {
  display: flex;
  margin-top: 18px;
  padding-left: 0 !important;

  li {
    list-style: none;
    margin-right: 30px;
    font-size: 26px;
    font-weight: 500;
    font-family: teko;
    cursor: pointer;

    &.active {
      color: #8364e2;
    }
  }
}

.profile_stats {
  display: flex;

  > div {
    margin-right: 16px;
    display: flex;
    align-items: center;

    span,
    .tag {
      margin-right: 8px;
      font-size: 14px;
      color: #696969;
      font-weight: 500;
    }

    .tag {
      background-color: #696969;
      color: white;
      font-size: 12px;
      padding: 2px 12px;
      border-radius: 10px;
    }
  }
}

.detail_img {
  min-height: 500px;
  object-fit: cover;
  margin-bottom: 30px;
}

.item_info {
  .collection_name {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 10px;
    cursor: pointer;
    color: #8364e2;
  }

  .detail_name {
    font-size: 50px;

    span {
      font-size: 20px;
      margin-left: 14px;
      color: #c4c4c4;
      vertical-align: text-top;
      cursor: pointer;

      &:hover i {
        color: pink;
      }

      & .liked {
        color: red;
      }
    }
  }

  .detail_creator {
    span {
      font-size: 14px;
      font-weight: 600;
    }

    span:last-child {
      color: #8364e2;
    }
  }
}

.item_author {
  .curr_price {
    color: #696969;
    font-size: 12px;
    font-weight: 500;
  }

  .item_author_price {
    display: flex;
    align-items: flex-end;
    margin: 2px 0 6px 0;

    p {
      font-size: 25px;
      line-height: 1;
      font-weight: 700;
    }

    span {
      font-size: 14px;
      font-weight: 500;
      flex-wrap: wrap;
    }
  }

  .item_author_owner {
    margin-left: 2px;
    display: flex;
    flex-direction: column;

    span {
      font-size: 12px;
      font-weight: 500;
    }

    > span:first-child {
      color: #696969;
      line-height: 8px;
    }

    > span:last-child {
      color: #8364e2;
    }

  }
}

.item_author_desc {
  margin-top: 10px;

  h4 {
    font-size: 24px;
    font-family: familjenGrotesk;
    font-weight: bold;
  }

  p {
    font-weight: 500;
    font-size: 14px;
  }
}

.item_net_activity {
  margin-top: 30px;

  h4 {
    font-size: 24px;
    font-family: familjenGrotesk;
    font-weight: bold;
  }

  table {
    th,
    td {
      font-size: 14px;
      font-weight: 500;
    }

    th {
      background-color: #d5d5d5;
      text-align: center;
      color: black;
    }

    td {
      text-align: left;
      color: #3c3c3b;
    }
  }
}

.item_price_history {
  margin-top: 30px;

  h4 {
    font-size: 24px;
    font-family: familjenGrotesk;
    font-weight: bold;
  }

  > div {
    display: flex;
    align-items: flex-end;

    select {
      width: 120px !important;
      height: 30px;
      margin-right: 40px;
    }

    .curr_price {
      font-size: 12px;
    }

    .item_author_price {
      display: flex;
      align-items: flex-end;

      p {
        font-size: 16px;
        line-height: 10px;
        margin: 4px;
        font-weight: 700;
      }

      span {
        color: #696969;
        font-size: 12px;
        font-weight: 500;
      }
    }
  }

  .item_chart {
    width: 100%;

    img {
      width: 100%;
      height: 150px;
    }
  }
}

.item_more_coll {
  margin-top: 30px;

  h4 {
    font-size: 24px;
    font-family: familjenGrotesk;
    font-weight: bold;
    margin-bottom: 12px;
  }

  > div {
    display: flex;

    .more_coll {
      font-size: 20px;
      width: 30%;
      margin-right: 3.33%;
      padding: 8px;
      border: 1px solid lightgray;
      background-color: white;
      border-radius: 5px;

      img {
        width: 100%;
        height: 140px;
        object-fit: cover;
        margin-bottom: 12px;
      }

      h6 {
        font-size: 20px;
        margin-bottom: 0;
      }
    }
  }
}

.item_details {
  margin-top: 20px;

  h4 {
    font-size: 24px;
    font-family: familjenGrotesk;
    font-weight: bold;
    margin-bottom: 6px;
    text-align: left;
  }

  .table {
    th {
      text-align: left;
      padding-left: 0;
    }

    td {
      text-align: right;
      padding-left: 0;
    }
  }
}

.detail_properties {
  margin-top: 20px;

  h4 {
    font-size: 24px;
    font-family: familjenGrotesk;
    font-weight: bold;
    margin-bottom: 6px;
    text-align: left;
  }

  > div {
    display: flex;
    flex-wrap: wrap;

    .property {
      width: 47%;
      margin-right: 3%;
      height: 122px;
      background-color: #f0f0f0;
      padding: 8px;
      margin-bottom: 12px;

      h6 {
        font-size: 12px;
        color: #a3a2a2;
        margin-bottom: 16px;
      }

      .detail_title {
        margin-bottom: 25px;
        font-weight: 600;
      }

      .detail_stat {
        font-size: 12px;
        color: #a3a2a2;
      }
    }
  }
}

.search-results {
  > h1 {
    font-size: 45px;
    margin-bottom: 20px;
    font-weight: 400;

    span {
      font-size: 25px;
      margin-left: 10px;
    }
  }
}

.create-coll {
  .cc-img-wrap {
    overflow: hidden;
    margin-top: 10px;
    border: 2.5px dotted rgb(189, 189, 189);

    &.logo {
      width: 160px;
      height: 160px;
      border-radius: 50%;
    }

    &.featured {
      height: 200px;
      // max-width: 350px;
      width: 100%;
      border-radius: 8px;
    }

    &.banner {
      height: 200px;
      // max-width: 750px;
      width: 100%;
      border-radius: 8px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .coll-input,
  .coll-button {
    margin-top: 6px;

    input,
    textarea,
    button,
    select {
      border-radius: 8px;
      border: 2px solid lightgray !important;
    }

    select {
      padding: 8px;
      margin-bottom: 20px;
      font-size: 1rem;
      width: 100%;
    }
  }

  .coll-button {
    button {
      font-size: 16px;
      font-weight: 800;
    }
  }

  .coll-links {
    margin-top: 6px;
    border-radius: 8px;
    border: 2px solid lightgray !important;

    > div {
      position: relative;

      input {
        padding-left: 40px;
        border: 0 !important;
        border-bottom: 2px solid lightgray !important;
        margin-bottom: 0 !important;
      }

      span {
        position: absolute;
        left: 15px;
        top: 10px;
      }

      &:last-child {
        input {
          border-bottom: 0 !important;
        }
      }
    }
  }

  .payment-tokens {
    display: flex;
    margin: 8px 0;

    .item {
      padding: 8px;
      width: 160px;
      border-radius: 8px;
      border: 1px solid lightgray !important;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;

      > div:first-child {
        margin-right: 20px;
      }

      > div:last-child {
        > p:first-child {
          margin-bottom: 4px;
        }
      }
    }
  }

  .create-coll-btn {
    margin: 30px 0;

    button {
      height: 55px;
      width: 110px;
      color: white;
      font-weight: 700;
      font-size: 16px;
      outline: none;
      border: none;
      border-radius: 12px;
      background-color: rgb(12, 95, 220);

      &.disabled {
        background-color: rgba(12, 95, 220, 0.223);
      }
    }
  }
}

.error-500 {
  min-height: 100vh;
  background-color: #8364e2;
  color: white;
  padding: 25px 45px;
  display: flex;
  align-items: center;

  > div:first-child {
    flex: 1;
  }

  > div:last-child {
    width: 190px;
    text-align: center;

    img {
      max-width: 185px;
    }
  }

  h2,
  h4,
  p {
    color: white;
  }

  .exclaim {
    font-size: 50px;
  }

  label {
    font-size: 18px;
    margin-bottom: 6px;
  }

  input {
    max-width: 340px;
    background: #cda7f4;
    border: 1px solid #ffffff;
  }

  .input-1 {
    height: 72px;
  }

  .input-2 {
    height: 40px;
  }

  button {
    background: #ffffff;
    border: 1px solid #8364e2;
    border-radius: 5px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #8364e2;
    padding: 8px 22px;
  }
}

.error-404 {
  min-height: 100vh;
  background-color: white;
  color: black;
  padding: 25px 45px;
  display: flex;

  > div:first-child {
    flex: 1;
  }

  > div:last-child {
    width: 190px;
    text-align: center;

    img {
      max-width: 185px;
      margin-top: 80px;
    }
  }

  h2 {
    font-size: 34px;
    font-weight: 600;
  }
}

.text-underline {
  text-decoration: underline;
}

.text-purple {
  color: #8364e2 !important;
}

.text-green {
  color: #549205 !important;
}

.text-gray {
  color: #8c8c8c;
}

.text-deepGray {
  color: #576b75;
}

.text-red {
  color: #ff0303;
}

.text-black {
  color: black;
}

.text-blue {
  color: rgb(61, 134, 230);
}

.warning-custom {
  position: absolute;
  top: -16px;
}

.loader {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal.custom.modal-bg {
  .modal-content {
    // background-image: url("img/modal-bg.png");
    background-size: cover;
    // height: 300px;
  }
}

.modal.custom.bigger {
  .modal-dialog {
    @media (min-width: 576px) {
      max-width: 660px;
      width: 100%;
    }
  }
}

.modal.custom.connect-wallet {
  .modal-dialog {
    @media (min-width: 576px) {
      max-width: 750px;
      width: 100%;
    }
  }

  .rounded.bigger.custom {
    min-width: 60px;
    max-width: 60px;
    height: 60px;

    img {
      width: 40px;
    }
  }

  .modal-footer {
    .btn-purple {
      width: 94%;
    }
  }
}

.modal .modal-dialog {
  width: 98% !important;
  margin-left: auto;
  margin-right: auto;
}

.modal.custom.smaller {
  .modal-dialog {
    @media (min-width: 576px) {
      max-width: 450px;
      width: 100%;
    }
  }

  .modal-header {
    justify-content: center;
    position: relative;

    .btn-close {
      margin: 0;
      position: absolute;
      right: 4px;
      background-size: 0.7em;
    }
  }

  .modal-body {
    padding-bottom: 0;
  }

  .modal-footer {
    justify-content: center;
    border-top: 2px solid rgb(45, 94, 228);
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
  }
}

.modal.custom {
  .modal-title {
    font-weight: normal;
    font-size: 26px;
    font-family: familjenGrotesk;
  }

  .modal-header {
    padding-left: 40px;
    border: none;
  }

  .modal-body {
    padding-top: 0;
  }

  .modal-body,
  .modal-footer {
    padding-left: 40px;
    padding-right: 40px;
    border: none;
  }

  .modal-dialog {
    @media (min-width: 576px) {
      max-width: 620px;
      width: 100%;
    }
  }
}

.connect-wallet-modal {
  h4 {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 4px;
    font-family: familjenGrotesk;
  }

  p {
    font-size: 12px;
    font-weight: 300;
    margin-top: 0;
  }
}

.rounded.custom {
  min-width: 35px;
  max-width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50% !important;
  border: 1px solid #c4c4c4;
  color: #8364e2;
  font-size: 14px;
  margin-right: 12px;
}

.rounded.bigger.custom {
  min-width: 70px;
  max-width: 70px;
  height: 70px;
  border: 5px solid #c4c4c4;
  overflow: hidden;

  img {
    width: 42px;
  }
}

.btn-purple {
  background-color: #8364e2;
  border: 1px solid #8364e2;
  color: white;
  outline: none;
  padding: 6px 10px;
  border-radius: 4px;
  font-size: 13px;

  &.tiny {
    font-size: 10px;
    background-color: #8364e2;
    color: #8364e2;
    padding: 2px 8px;
    margin: 0 5px;
    height: fit-content;
    min-width: fit-content;
  }

  &:disabled {
    background-color: #9c6dcf;
    border: 1px solid #8364e2;
    color: #8364e2;
  }
}
.btn-red {
  background-color: white !important;
  border: 1px solid red;
  text-transform: uppercase;
  color: red;
  outline: none;
  padding: 6px 10px;
  border-radius: 4px;
  font-size: 13px;

  &.solid{
  color: white;
  background-color: red !important;

  }
}

.btn-transparent {
  background-color: white;
  border: 1px solid #8364e2;
  color: #8364e2;
  outline: none;
  padding: 6px 10px;
  border-radius: 4px;
  font-size: 13px;
}

.btn-gray {
  background-color: #c4c4c4;
  border: 1px solid #696969;
  color: #696969;
  outline: none;
  padding: 6px 40px;
  border-radius: 4px;
  font-size: 13px;
}

.btn-green {
  background-color: #91f411;
  border: 1px solid #549205;
  color: #549205;
  outline: none;
  padding: 6px 40px;
  border-radius: 4px;
  font-size: 13px;
}

.btn-mint-green {
  background-color: #5f8c17;
  border: 1px solid #5f8c17;
  color: white;
  outline: none;
  padding: 6px 40px;
  border-radius: 4px;
  font-size: 13px;
}

.all-modals {
  button {
    display: block;
    margin-bottom: 6px;
  }
}

.input-div {
  label {
    display: block;
  }

  input {
    display: block;
    border: 1px solid #a3a2a2;
    max-width: 360px;
    width: 100%;
    height: 35px;
  }
}

.modal-nft-img {
  width: 100%;
  overflow: hidden;

  img {
    width: 100%;
  }

  img.smaller {
    width: 130px;
    height: 130px;
  }

  img.sell-this-nft {
    width: 100%;
    height: 200px;
    object-fit: cover;
    object-position: 50% 50%;
  }
}

.btn-purple.wide {
  padding: 6px 50px;
}

.dot-purple {
  width: 12px;
  height: 12px;
  background-color: #9747ff;
  border-radius: 50%;
}

.dot-light-purple {
  width: 10px;
  height: 10px;
  background-color: #caa1ff;
  border-radius: 50%;
  margin: 0 8px;
}

.flex-1 {
  flex: 1;
}

.wallet-types {
  .wallet {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    img {
      width: 22px;
    }

    > div:last-child {
      p {
        text-transform: uppercase;
        margin-bottom: 0;
        font-size: 11px;
      }
    }
  }
}

.c-pointer {
  cursor: pointer;
}

.max-80 {
  max-width: 80px;
  width: 100%;
}

.max-200 {
  max-width: 200px;
  width: 100%;
}

.max-265 {
  max-width: 265px;
  width: 100%;
}

.min-265 {
  min-width: 225px;
  width: 100%;
}

.max-520 {
  max-width: 520px;
  width: 100%;
}

.socials.custom {
  display: flex;
  justify-content: space-between;

  &.onboarding {
    max-width: 300px;

    span {
      font-size: 35px;
    }
  }

  span {
    font-size: 30px;

    .fa-facebook {
      color: #1877f2;
    }

    .fa-twitter {
      color: #1da1f2;
    }

    .fa-instagram {
      color: #000100;
    }

    .fa-linkedin {
      color: #0a66c2;
    }
  }
}

.offer-amount {
  display: flex;
  margin-top: 5px;

  input {
    max-width: 170px;
    width: 100%;
    height: 30px;
  }

  select {
    width: 100%;
    height: 30px;
    padding-left: 20px;
  }

  .eth-icon,
  .btc-icon {
    position: absolute;
    left: 7px;
    top: 2px;
    font-size: 18px;
    color: rgb(56, 56, 56);
  }

  small {
    position: absolute;
    top: 6px;
    right: -43px;
  }
}

.price-amount {
  display: flex;
  margin-top: 5px;

  .outer-input {
    max-width: 170px;
    width: 100%;
    height: 30px;
    margin-right: 3px;
  }

  select {
    width: 91px;
    height: 30px;
    padding-left: 20px;
  }

  .eth-icon,
  .btc-icon {
    position: absolute;
    left: 7px;
    top: 2px;
    font-size: 18px;
    color: rgb(56, 56, 56);
  }
}

.offer-duration {
  input {
    width: 100%;
    height: 30px;
  }
}

.custom-radios {
  display: flex;
  align-items: center;
  margin-top: 12px;
  border-bottom: 0.8px solid #5f5f5f;

  > div {
    display: flex;
    align-items: center;

    label {
      cursor: pointer;
      padding-bottom: 6px;

      &.selected {
        border-bottom: 2.5px solid #8a4fc9;
      }
    }

    input {
      margin-right: 6px;
      cursor: pointer;
      margin-top: 2px;
    }

    .c-exclaim {
      color: #8364e2;
      margin-left: 3px;
      font-size: 10px;
    }
  }

  > div:first-child {
    margin-right: 10%;
  }
}

.custom-fee {
  padding-bottom: 6px;
  border-bottom: 0.8px solid #5f5f5f;
}

.sellthis-title {
  width: 80px;
}

.auction-type {
  padding: 12px;
  padding-top: 16px;
  border: 1px solid #5f5f5f;
  margin-top: -23px;
}

.title-border {
  background: white;
  position: relative;
  width: 145px;
}

.search-section {
  margin-top: 20px;
  display: flex;

  .mobile-accord {
    display: none;
  }

  .desktop-accord {
    display: block;
    margin-right: 18px;
  }

  .filters {
    padding: 14px 16px;
    min-width: 270px;
    width: 270px;
    background-color: #e6e6e6;

    .top {
      display: flex;
      align-items: center;
      margin-bottom: 15px;

      .filter-icon {
        background-color: #bdbdbd;
        width: 28px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        overflow: hidden;
      }

      h6 {
        margin: 0 16px 0 8px;
        font-size: 20px;
      }
    }

    .accordion {
      .accordion-item {
        background-color: transparent;
        border: none;

        .accordion-header {
          line-height: 1;

          .accordion-button {
            padding: 12px;
            font-size: 22px;
            font-weight: 600;
            background-color: #e0e0e0;
            border: none;
            box-shadow: none;
            border-radius: 6px;
            text-transform: capitalize;

            &:not(.collapsed) {
              color: #212529;
              background-color: #c7c7c7;
            }
          }
        }

        .accordion-body {
          padding: 8px;

          > div {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 2px 0;

            span {
              text-transform: capitalize;
            }
          }

          .filter-inputs {
            select {
              width: 68px;
              height: 28px;
            }

            input {
              width: 55px;
              height: 28px;
            }
          }
        }
      }
    }

    &.myProfile {
      background-color: transparent;

      .myProfile-select-wrapper {
        > .myProfile-select:nth-of-type(odd) {
          background-color: #e6e6e6;
        }
      }

      .myProfile-select {
        display: flex;
        justify-content: space-between;
        padding: 6px 10px;

        span {
          font-size: 16px;
          font-weight: 600;
          text-transform: capitalize;
        }

        input {
          cursor: pointer;
        }
      }
    }
  }

  .filter-content {
    flex: 1;

    .mobile-filter-right {
      display: none;

      @media screen and (max-width: 768px) {
        display: block;

        .filter-right {
          flex-direction: column;
          align-items: flex-start;
          padding-left: 16px;
        }

        .dropdown-toggle.btn {
          padding: 6px 20px;
          padding-left: 10px;
          &::before {
            display: none;
          }

          &::after {
            color: white;
            top: 10px;
          }

          &:hover {
            border: 1px solid #5b3585;
            box-shadow: none;
            background-color: #5b3585 !important;
            color: white !important;
          }
        }
      }
    }

    .filter-right {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      flex: 1;
      width: 100%;

      @media screen and (max-width: 768px) {
        &.desktop {
          display: none;
        }
      }

      h6 {
        color: #8364e2;
        margin-right: 28px;
        margin-bottom: 0;
        font-size: 20px;
        cursor: pointer;
        font-weight: 500;
        text-transform: capitalize;

        &.active {
          color: black;
        }
      }
    }

    .filter-tags {
      margin-top: 12px;
      display: flex;
      flex-wrap: wrap;

      &.myProfile {
        align-items: center;

        > div {
          cursor: pointer;
        }
        span {
          margin-top: -6px;
        }
      }

      > div {
        background-color: white;
        color: #8364e2;
        font-size: 14px;
        border-radius: 5px;
        margin-right: 10px;
        padding: 2px 10px;
        margin-bottom: 6px;

        span {
          font-size: 12px;
          cursor: pointer;
        }
      }

      span {
        cursor: pointer;
      }
    }

    .mobile-filter {
      display: none;
    }

    .nft__item h4 {
      font-size: 35px;
    }

    .price-eth {
      font-size: 20px;
    }
  }
}

.offer-wallet-body {
  .modal-nft-img {
    margin-right: 30px;
  }
}

.profile-crop .ReactCrop {
  width: 200px;
}

.user-preview {
  margin-bottom: 20px;

  .banner-preview {
    img {
      height: 65px;
      object-fit: cover;
      object-position: top;
    }
  }

  .profile-preview {
    margin-left: 30px;
    margin-top: -35px;
    display: flex;
    align-items: center;

    .round-img {
      width: 70px;
      height: 70px;
      overflow: hidden;
      border-radius: 50%;
      border: 3px solid white;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .user-text {
      margin-left: 20px;

      h6 {
        margin: 0;
        font-size: 20px;
      }

      > h6:first-child {
        margin-bottom: 6px;
        color: white;
      }
    }
  }
}

.table-wrapper {
  width: 100%;
  max-height: 360px;
  overflow-x: auto;
  border: 0.5px solid #CCCCCC;
  .table {
    min-width: 700px;
  }
}

.mobile-link {
  display: none;

  .side-feature,
  .de-menu-profile1 {
    padding: 15px 0;
    margin-left: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  // .side-feature.con_discon button {
  //   outline: none;
  //   border: none;
  //   color: #a3a2a2;
  //   opacity: 0.6;
  //   background-color: transparent;
  //   white-space: nowrap;
  //   margin-left: 0 !important;
  //   padding-left: 0;
  // }

  .de-menu-profile1 img {
    margin-left: 0 !important;
  }
}

.toast-wrapper {
  position: fixed;
  top: 80px;
  width: 100%;
  z-index: 9999;

  .custom-toast {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px 10px 20px;
    max-width: 100%;
    margin: 0 auto;
    width: 99%;
    color: white;
    border-radius: 10px;

    span.info-text {
      font-weight: 600;
    }

    span.icon-close {
      font-size: 18px;
      cursor: pointer;
    }

    button {
      text-transform: capitalize;
      border: 1px solid white;
      background-color: transparent;
      padding: 6px 15px;
      border-radius: 4px;
      color: white;
      margin-left: 20px;
    }

    &.success {
      background-color: #549205;
    }

    &.error {
      background-color: #ff0303;
    }
  }
}

.landing {
  padding-bottom: 50px;

  .m--20 {
    margin-top: -20px !important;
  }

  .landing-intro {
    display: flex;
    align-items: center;
    margin-top: 20px;

    > div:first-child {
      width: 54%;
      margin-right: 2%;
    }

    > div:last-child {
      margin-left: 2%;
      width: 44%;
      display: flex;
      align-items: flex-end;

      .landing-img-wrap {
        position: relative;
        margin-right: 10px;
        max-width: 240px;

        .your-work {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background-color: rgba(0, 0, 0, 0.74);
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .landing-right {
        min-width: 109px;
      }

      img {
        width: 100%;
        height: 250px;
        object-fit: cover;
        object-position: top;
      }
    }
  }

  .landing-line {
    background-color: #838383;
    height: 2.5px;
    max-width: 900px;
    margin: 40px auto;
  }

  .built-for {
    .built-for-list {
      display: flex;
      flex-wrap: wrap;
      max-width: 700px;
      width: 100%;
      margin: 0 auto;

      p {
        font-size: 13px;
        color: black;
        width: 33.33%;
        margin: 0;
        text-align: center;
      }
    }
  }

  .benefits {
    .benefits-list {
      display: flex;
      flex-wrap: wrap;

      > div {
        width: 44%;
        margin-left: 3%;
        margin-right: 3%;
        margin-bottom: 30px;
        text-align: center;

        h3 {
          margin-bottom: 0;
          font-size: 26px;
        }

        p {
          margin-top: 0;
        }
      }
    }
  }

  .landing-hiw {
    max-width: 700px;
    margin: 0 auto;

    .how-it-works {
      > div {
        display: flex;
        margin-bottom: 15px;

        p {
          color: black;
        }

        .hiw-title {
          margin-top: 0;
          margin-bottom: 0;
          font-size: 18px;
        }

        .hiw-desc {
          margin-top: 2px;
          font-size: 11px;
        }
      }

      .number-wrap {
        min-width: 35px;
        height: 35px;
        border: 2.5px solid #8364e2;
        border-radius: 50%;
        color: #8364e2;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        margin-right: 13px;
        font-size: 16px;
      }
    }
  }

  .brainstorm {
    max-width: 460px;
    margin: 0 auto;

    h1 {
      font-size: 46px !important;
      font-weight: 300 !important;
    }
  }

  .creators-love {
    padding-bottom: 100px;

    h1 {
      font-size: 46px !important;
      font-weight: 300 !important;
      text-align: center !important;
    }
  }

  .faq {
    max-width: 690px;
    width: 100%;
    margin: 0 auto;

    h1 {
      font-size: 46px !important;
      font-weight: 300 !important;
      text-align: center !important;
    }

    .faq-list {
      .faq-question {
        font-weight: 600;
        color: black;
        margin-bottom: 0;
      }

      .faq-answer {
        font-size: 12px;
        color: black;
        margin-top: 0;

        > span:first-child {
          font-size: 14px;
        }
      }
    }
  }

  .security {
    max-width: 690px;
    width: 100%;
    margin: 0 auto;

    h1 {
      font-size: 46px !important;
      font-weight: 300 !important;
      text-align: center !important;
    }
  }
}

.mint-green-info {
  h4 {
    font-size: 40px;
    font-weight: 500;
    margin-bottom: 0;
  }

  p {
    margin-top: 0;
    font-weight: 500;
    color: black;
  }

  .minting-nft {
    max-width: 1000px;
    width: 100%;
    margin: 20px auto 0 auto;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    > div:first-child {
      width: 60%;
      margin-right: 5%;
    }

    > div:last-child {
      width: 30%;

      img {
        max-height: 240px;
      }
    }
  }

}

.break-line {
  background-color: #838383;
  height: 2.5px;
  max-width: 950px;
  margin: 40px auto;
}

.mint-round {
  margin-left: 5px;

  width: 22px;
  height: 22px;
  img {
    width: 22px;
    height: 22px;
  }
}

.profile-mint {
  img {
    width: 20px;
  }
}

.c-nft-blockchain {
  display: flex;

  .c-eth,
  .c-poly {
    position: relative;
    border: 1px solid #8364e2;
    border-radius: 10px;
    min-height: 125px;
    min-width: 120px;
    margin-right: 1.5rem;
    margin-bottom: 5px;
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #8364e2;
    @media (max-width: 640px) {
    min-height: 90px;
    min-width: 90px;
    }
    p {
      color: #8364e2;
    }

    span.inner {
      font-size: 40px;

      img {
        width: 35px;
      }
    }

    span.exclaim {
      width: 15px;
      height: 15px;
      border: 1px solid #8364e2;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 10px;
      position: absolute;
      right: 4px;
      bottom: 4px;
    }

    span.mint-small-white {
      width: 15px;
      height: 15px;
      position: absolute;
      right: 4px;
      top: 0;

      img {
        width: 100%;
        height: 100%;
      }
    }

    &.selected {
      background-color: #8364e2;
      color: white;

      .c-eth,
      .c-poly {
        color: white;
      }

      p {
        color: white;
      }

      span.exclaim {
        color: white;
        border-color: white;
      }
    }
  }

  &.n-type {
    align-items: flex-end;
    .c-eth,
    .c-poly {
      width: 120px;
      height: 140px;

      .inner {
        img {
          width: 46px;
        }
      }
    }

    .c-poly .inner img {
      width: 65px;
    }

    @media only screen and (max-width: 520px) {
      flex-direction: column;
      align-items: flex-start;
      > div {
        margin-bottom: 10px;
      }

      .number-nft {
        margin-left: 0 !important;
      }
    }
  }

  .number-nft {
    margin-left: 16px;

    input {
      padding: 6px 8px;
      max-width: 120px;
      width: 100%;
      margin-bottom: 0;
    }
  }
}

.c-nft-price {
  display: flex;
  justify-content: space-between;

  .inner {
    display: flex;
    align-items: flex-start;
  }

  .c-nft-select {
    width: 120px;
    margin-right: 6px;
  }

  .c-nft-input {
    max-width: 250px;
    width: 100%;

    input {
      width: 100%;
      margin-bottom: 0;
      padding: 6px 8px;
    }

    small {
      color: #696969;
    }
  }

  .c-nft-input-right {
    display: flex;

    input {
      max-width: 220px;
      width: 100%;
      margin-bottom: 0;
      padding: 6px 8px;
    }

    small {
      margin-top: 6px;
      margin-left: 6px;
      font-size: 18px;
    }
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;

    .c-nft-select {
      width: 30%;
      margin-right: 2%;
      max-width: unset;
    }

    .c-nft-input {
      width: 68%;
      max-width: unset;
    }

    .c-nft-input-right input {
      max-width: unset;
    }
  }
}

.form-switch .form-check-input {
  width: 40px;
  height: 24px;
}
.form-switch .form-check-input:focus {
  border-color: rgba(0, 0, 0, 0.25);
  outline: 0;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba(0,0,0,0.25)'/></svg>");
}
.form-switch .form-check-input:checked {
  background-color: #8364e2;
  border-color: #8364e2;
  border: none;
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba(255,255,255,1.0)'/></svg>");
}

@media only screen and (max-width: 991px) {
  .profile_stats {
    flex-wrap: wrap;

    > div {
      margin-bottom: 6px;
    }
  }

  .item_more_coll > div {
    flex-wrap: wrap;

    .more_coll {
      width: 46%;
      margin-right: 4%;
      margin-bottom: 10px;
    }
  }

  .landing {
    .landing-intro {
      flex-direction: column;

      > div:first-child {
        width: 100%;
        margin-bottom: 30px;
      }

      > div:last-child {
        width: 100%;
        margin-bottom: 20px;
        justify-content: flex-end;
      }
    }
  }
}

@media screen and (max-width: 850px) {
  .activity-table {
    overflow: visible;
  }

  .search-section {
    flex-direction: column;
    margin-top: 0;

    .mobile-accord {
      display: block;
    }

    .desktop-accord {
      display: none;
    }

    .filter-content {
      padding-top: 0;

      .mobile-filter {
        display: block;

        .dropdown-toggle.btn.show {
          color: #8364e2;
          background-color: #8364e2;
          border-color: #8364e2;
        }

        .dropdown-toggle.btn {
          padding: 6px 20px;
          padding-left: 10px;
          &::after {
            color: white;
            top: 10px;
          }

          &:hover {
            border: 1px solid #5b3585;
            box-shadow: none;
            background-color: #5b3585 !important;
            color: white !important;

            &::before {
              display: none;
            }
          }
        }

        .dropdown-menu.show {
          padding: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .landing {
    .benefits {
      .benefits-list > div {
        flex-direction: column;
        width: 94%;
      }
    }
  }
}

@media screen and (max-width: 620px) {
  .sell-auction-body {
    flex-direction: column;
  }

  .mobile-hide {
    display: none !important;
  }

  .mobile-link {
    display: block;

    .navbar-item {
      margin-left: 0 !important;
      border-bottom: none !important;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
  }
}

@media screen and (max-width: 550px) {
  .item-detail-buymake {
    flex-direction: column;
    align-items: flex-start !important;

    > button {
      margin-bottom: 10px;
      margin-right: 0 !important;
    }
  }

  .make-offer-body {
    flex-direction: column;

    > div:last-child {
      max-width: unset;
      width: 90%;
    }

    .offer-amount > input {
      max-width: unset;
      width: 50%;
    }
  }

  .offer-wallet-body .modal-nft-img {
    margin-right: 0;
  }

  .offer-wallet-body,
  .nft-modal-body {
    flex-direction: column;
  }

  .almost-done-footer {
    flex-direction: column;

    button {
      order: 2;
      margin-top: 10px;
      width: 220px;
    }

    > div {
      order: 1;
    }
  }

  .almost-done-body {
    .input-div input {
      max-width: unset;
      width: 100%;
    }
  }

  .home-dads {
    margin-left: 2%;
  }

  .jumbo-imgs.home_custom {
    flex-wrap: wrap;
    max-width: 300px;
    margin: 0 auto;

    .featured_btn {
      width: 46%;
      margin: 0 2%;
      margin-bottom: 14px;
    }

    img {
      width: 100%;
      height: 100px;
    }
  }

  .landing {
    .built-for {
      .built-for-list {
        flex-direction: column;
        align-items: center;
      }
    }
  }
}

@media screen and (max-width: 450px) {
  .my_profile .my_profile_name h4 {
    font-size: 20px !important;
  }
}

@media screen and (max-width: 420px) {
  .btn-main,
  .btn-secondary,
  .btn-transparent,
  .btn-purple,
  .btn-green {
    padding: 5px;
    font-size: 12px;
    line-height: 15px;
  }

  .sing-coll-title {
    font-size: 50px !important;
  }

  .my_profile_name {
    &.sing-coll {
      margin-left: 20px !important;
    }
  }

  .profile_nav li {
    font-size: 20px;
  }

  .no-result-h2 {
    font-size: 24px !important;
  }

  .item_detail_page {
    h4 {
      font-size: 18px !important;
    }
  }

  .item_info .detail_name {
    font-size: 40px;
  }

  .item_more_coll > div {
    flex-direction: column;

    .more_coll {
      max-width: 180px;
      width: 100%;
    }
  }

  .item_author .item_author_price p {
    font-size: 25px;
  }

  .breadcumb .mainbreadcumb .custom-container {
    width: 98% !important;
    padding-left: 10px;
    padding-right: 10px;
  }

  .sell-auction-footer {
    button {
      font-size: 10px;
    }
  }

  .jumbo-imgs.home_custom {
    img {
      height: 80px;
    }
  }

  .modal.custom.connect-wallet .rounded.bigger.custom {
    min-width: 43px;
    max-width: 43px;
    height: 43px;
  }

  .connect-wallet-footer {
    .btn-transparent {
      font-size: 10px;
      padding: 6px 10px;
      width: 100%;
    }

    > div:first-child {
      align-items: flex-start !important;
    }
  }
}